import React, { useState } from "react";
import style from "./inputField.module.scss";

export default function InputField({
  placeholder,
  handleChange,
  name,
  value,
  length,
  type,
  regexPattern,
  errorMessage,
  disabled,
  requiredError, width="600px"
}) {
  const [error, setError] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Check if regex pattern is provided
    if (regexPattern) {
      const regex = new RegExp(regexPattern);
      const isValid = regex.test(inputValue);
      setError(!isValid); // Set error state based on regex validation
    }

    // Pass the input value to parent component's handleChange function
    handleChange(e);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <input
        style={{ width: width }}
        type={type || "text"}
        className={style["input-field"]}
        placeholder={placeholder}
        onChange={handleInputChange}
        name={name}
        value={value}
        maxLength={length}
        disabled={disabled}
      />
      {(error || (requiredError && !value)) && (
        <span className={style["error-msg"]}>
          {requiredError && !value ? "This field is required" : errorMessage}
        </span>
      )}
    </div>
  );
}

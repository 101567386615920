import { dealerAppAuth } from ".";

class DaynamicPages {
    savePages(data) {
        const token = localStorage.getItem("accessToken");
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "post",
                    "dealer-app-auth/save-pages",
                    null,
                    data,
                    token
                );
                resolve(res);
            } catch (error) {
                reject(error);
            }
        });
    }

    getFaqs(params) {
        const token = localStorage.getItem("accessToken");
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "get",
                    "dealer-app-auth/get-pages",
                    params,
                    null,
                    token
                );
                resolve(res);
            } catch (error) {
                reject(error);
            }
        });
    }

    getAllPages() {
        const token = localStorage.getItem("accessToken");
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "get",
                    "dealer-app-auth/get-all-pages",
                    null,
                    null,
                    token
                );
                resolve(res);
            } catch (error) {
                reject(error);
            }
        });
    }

    removePage(param) {
        const token = localStorage.getItem("accessToken");
        console.log('token ==>', token)
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "put",
                    "dealer-app-auth/remove-page",
                    param,
                    {},
                    token
                );
                resolve(res?.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    saveDynamicPage(data) {
        const token = localStorage.getItem("accessToken");
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "post",
                    "dealer-app-auth/add-daynamic-page",
                    null,
                    data,
                    token
                )
                resolve(res);
            } catch (error) {
                reject(error)
            }
        })
    }

    getDynamicPage() {
        const token = localStorage.getItem("accessToken");
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "get",
                    "dealer-app-auth/get-daynamic-page",
                    null,
                    null,
                    token
                )
                resolve(res?.data);
            } catch (error) {
                reject(error)
            }
        })
    }

    removeDynamicpages(param) {
        const token = localStorage.getItem("accessToken");
        return new Promise(async (resolve, reject) => {
            try {
                const res = await dealerAppAuth(
                    "put",
                    "dealer-app-auth/delete-daynamic-page",
                    param,
                    {},
                    token
                )
                resolve(res?.data);
            } catch (error) {
                reject(error)
            }
        })
    }

}

export default new DaynamicPages();

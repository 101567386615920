import React, { useState, useEffect } from "react";
import style from "./inquiry.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import InqueryTable from "./components/table/InquiryTable";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../../components/buttons/multiSelect";
import DatePicker from "react-datepicker";
import { buyerApi } from "../../api/buyerLeadApi";
import "react-datepicker/dist/react-datepicker.css";
import {
  getBuyerList,
  getLeadList,
} from "../../store/buyerLead/buyerLeadAction";
import { Box, Pagination, Stack, Typography } from "@mui/material";

export default function InquiryManagement() {
  const { buyerLeadList, total } = useSelector((state) => state.buyerLead);
  const [searchText, setSearchText] = useState("");
  const [datas, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [buyerLeadStatus, setbuyerLeadStatus] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [filter, setFilter] = useState({
    status: "1",
    dealerId: localStorage.getItem("dealerId"),
    enquiry: ""
  });
  const [reset, setReset] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSearch = () => {
    let filtered = datas;

    if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.phone.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedDate) {
      filtered = filtered.filter((item) =>
        item.created_at.startsWith(selectedDate)
      );
    }

    setFilterData(filtered);
  };

  const [pagination, setPagination] = useState({
    pageSize: 1,
    pageIndex: 0,
  });

  useEffect(() => {
    handleSearch();
  }, [searchText, selectedDate, datas]);

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await getLeadList({ page: pagination.pageIndex });
        setData(response.data);
        setFilterData(response.data);
        setCurrentPage(response.currentPage);
        setTotalCount(response.totalCount);
        setTotalPageCount(response.totalPageCount);
        setHasNextPage(response.hasNextPage);
      } catch (error) {
        console.error("Error fetching lead data:", error);
      }
    };

    fetchLeadData();
  }, [pagination.pageIndex]);

  useEffect(() => {
    dispatch(getBuyerList());
  }, []);

  const applyFilter = () => {
    if (Object.values(filter).some((value) => value !== "")) {
      getBuyerList();
    }
  };

  const resetFilter = () => {
    setFilter({
      status: "1",
      dealerId: localStorage.getItem("dealerId"),
      enquiry: ""
    });

    setReset(true);
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, pageIndex: page }));
    setCurrentPage(page);
  };


  const exportToExcel = () => {
    const dataWithoutAction = buyerLeadList.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "buyer_data.xlsx");
    });
  };

  const enquiryType = async () => {

      try {
        const res = await buyerApi.getEnquiryType({dealerId: localStorage.getItem("dealerId")});
        console.log("janmejay response " , res.data)
        if (res) {
          setbuyerLeadStatus(res.data);
        }
      } catch (err) {
        console.error("Failed to fetch customer info:", err);
      }
    };

     useEffect(() => {
      console.log("fiaddnsafbdjfbdjfbdbbfjdbf")
      enquiryType();
      }, []);

  

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Enquiry ({totalCount})
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Name / Phone"}
            handleChange={handleChange}
          />

          <div className={style["date-picker-container"]}>

            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className={style["date-change"]}
              placeholder="Select Date"
              style={{ color: selectedDate ? "black" : "gray" }}
            />
            

          </div>
          <div className={style["filters-wrapper"]}>
          <MultiSelect
              name="status"
              handleChange={(e) =>
                setFilter({ ...filter, enquiry: e.value })
              }
              items={buyerLeadStatus.map((status) => ({
                label: status.name,
                value: status.service_id,
              }))}
              selectedItems={filter?.enquiry}
              hint={"Enquiry Type"}
              isSearchable={true}
              containerStyle={style["selectable-button-container-style"]}
              selectClass={style["filterDropDown"]}
            />
          </div>
          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={" Filter"}
            fontSize={"1rem"}
            onClick={() => applyFilter()}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => resetFilter()}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          />
        </div>
      </div>
      <InqueryTable
        data={filterData} // Ensure this uses filtered data
        pagination={pagination}
        setPagination={setPagination}
        total={total}
      />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={totalPageCount}
          page={currentPage}
          onChange={(event, page) => handlePageChange(page)}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </div>
  );
}

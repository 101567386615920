import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let auth = localStorage.getItem("accessToken");
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;



export const formatIndianCurrency = (amount) => {
  if (!amount) return "";

  const x = amount.toString().replace(/\D/g, "");

  const lastThree = x.substring(x.length - 3);
  const otherNumbers = x.substring(0, x.length - 3);

  const formattedValue =
    otherNumbers !== ""
      ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
      : lastThree;

  return formattedValue;
};

export const formatIndianOnlyProfit = (amount) => {
  if (!amount) return "";

  // Check if the value is negative
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);

  const x = absoluteAmount.toString().replace(/\D/g, "");
  const lastThree = x.substring(x.length - 3);
  const otherNumbers = x.substring(0, x.length - 3);

  const formattedValue =
    otherNumbers !== ""
      ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
      : lastThree;

  // Return the formatted value with the negative sign if applicable
  return isNegative ? `-${formattedValue}` : formattedValue;
};


export const formatDate = (date) =>
  date ? new Date(date).toISOString().split('T')[0] : '';




// Other existing exports
export { PrivateRoutes };

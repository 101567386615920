import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import MultiSelect from "../../../components/buttons/multiSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { buyerApi } from "../../../api/buyerLeadApi";
import CloseIcon from "@mui/icons-material/Close";
import { getValue, styled } from "@mui/system";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  MenuItem,
  Grid,
  IconButton,
  Backdrop,
  Modal,
} from "@mui/material";
import CustomerPreference from "../step1/index";
import { carApi } from "../../../api/carApi";
import { getCarList, getLeadCarList } from "../../../store/car/carAction";
import { publicApi } from "../../../api/publicApi";
import DetailTable from "../../../components/tables/SuggestedCars";
import LeadInventory from "../../../components/tables/LeadInventory";

const Step0 = ({ setCurrentStep, id }) => {
  const [dealers, setDealers] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [buyerLeadStatus, setbuyerLeadStatus] = useState([]);
  const [data, setData] = useState([]);
  const [userNumber, setContactNumber] = useState("");
  const [brandFilter, setBrandFilter] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const brandValues = brandFilter.map((item) => item.value);
  const [commentsHistory, setCommentHistory] = useState([]);

  const [userName, setUserName] = useState("");
  const [commentRowId, setCommentRowId] = useState(null);

  const [varientList, setVarientList] = useState([]);
  const [carList, setCarList] = useState([]);
  const [leadCarList, setLeadCarList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modelArrayList, setmodelArrayList] = useState([]);
  // const [brandList, setBrandList] = useState([]);
  const [filter, setFilter] = useState({
    vehicle_id: "",
    make: "",
    model_id: "",
    varientName: "",
    store: "",
    status: "1",
    dealerId: localStorage.getItem("dealerId"),
    is_website: 0,
    is_motorpedia: 0,

    is_not_listed: 0,
  });

  const [preferences, setPreferences] = useState({
    leadId: +id.path,
    comment: "",
    statusId: "",
    followDate: null,
    name: "",
    email: "",
  });
  console.log("preferences", preferences);

  const [temPreferences, setTempPreferences] = useState({
    leadId: +id.path,
    comment: "",
    statusId: "",
    followDate: null,
    name: "",
    email: "",
    mobile: "",
  });

  const navigate = useNavigate();

  const buyerLeadStstus = async () => {
    try {
      const res = await buyerApi.getBuyerLeadStatus();
      if (res) {
        setbuyerLeadStatus(res.data);
      }
    } catch (err) {
      console.error("Failed to fetch customer info:", err);
    }
  };

  const handleStatusChange = (event) => {
    const statusId = event.target.value;
    setPreferences((prev) => ({ ...prev, statusId }));
    setTempPreferences((prev) => ({ ...prev, statusId }));
  };

  const commentHistory = async () => {
    try {
      setUserName(preferences.name);
      setContactNumber(preferences.mobile);

      const res = await buyerApi.getCustomerCommentHistory(id.path);
      setCommentHistory(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    commentHistory();
  }, []);

  const customerStatus = async (params = {}) => {
    try {
      const res = await buyerApi.getCustomerInfo({ leadId: id.path });

      setCustomerList(res?.data);

      const followDate = res?.data[0]?.followDate
        ? new Date(res.data[0].followDate)
        : null;
      const statusId = res?.data[0]?.statusId || null;
      const name = res?.data[0]?.name || "";
      const email = res?.data[0]?.email || "";
      const mobile = res?.data[0]?.mobile || "";

      setPreferences((prev) => ({
        ...prev,
        followDate,
        statusId,
        name,
        email,
        mobile,
      }));

      setTempPreferences((prev) => ({ ...prev, followDate }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    navigate("/buyer-lead-management");
  };

  const handleSave = async () => {
    const isAllFieldsEmpty = Object.entries(temPreferences)
      .filter(([key]) => key !== "leadId")
      .every(([_, value]) => value === "" || value === null);

    if (isAllFieldsEmpty) {
      toast.error("Please fill at least one field!");
      return;
    }

    if (isAllFieldsEmpty) {
      toast.error("Please fill at least one field!");
      return;
    }

    const today = moment().startOf("day");
    if ([2, 1, 3, 4, 5, 6].includes(+temPreferences.statusId)) {
      if (!temPreferences.followDate) {
        toast.error("Follow-up date Required.");
        return;
      }

      const followDateMoment = moment(temPreferences.followDate, "YYYY-MM-DD");
      if (followDateMoment.isBefore(today)) {
        toast.error("Follow-up date cannot be in the past.");
        return;
      }
    }

    const cleanedPreferences = Object.fromEntries(
      Object.entries(temPreferences).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );

    try {
      const response = await buyerApi.addUpdateCustomerInfo(cleanedPreferences);
      toast.success("Data saved successfully!");
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
  };

  const handleSaveNext = async () => {
    const isAllFieldsEmpty = Object.entries(preferences)
      .filter(([key]) => key !== "leadId")
      .every(([_, value]) => value === "" || value === null);

    if (isAllFieldsEmpty) {
      toast.error("Please fill at least one field!");
      return;
    }

    const today = moment().startOf("day");
    if ([2, 1, 4, 5, 6].includes(+preferences.statusId)) {
      if (!preferences.followDate) {
        toast.error("Follow-up date is required.");
        return;
      }

      const followDateMoment = moment(preferences.followDate, "YYYY-MM-DD");
      if (followDateMoment.isBefore(today)) {
        toast.error("Follow-up date cannot be in the past.");
        return;
      }
    }

    const cleanedPreferences = Object.fromEntries(
      Object.entries(preferences).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );

    try {
      const result = await buyerApi.addUpdateCustomerInfo(cleanedPreferences);
      toast.success("Data saved successfully!");
      setCurrentStep(1);
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred.");
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setPreferences((prevDetails) => ({
      ...prevDetails,
      followDate: formattedDate,
    }));

    setTempPreferences((prevDetails) => ({
      ...prevDetails,
      followDate: formattedDate,
    }));
  };

  useEffect(() => {
    // customer();
    customerStatus();
  }, []);

  useEffect(() => {
    buyerLeadStstus();
  }, []);

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ model: filter?.model_id });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getModelData = async () => {
    try {
      if (filter?.model_id) {
        const res = await publicApi.getModelData({
          group_id: filter?.model_id,
        });
        setmodelArrayList(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFilteredCarList = (page) => {
    const makeArray = Array.isArray(filter?.make)
      ? filter.make
      : [filter.make].filter(Boolean);
    const modelArray = filter.model_id
      ? Array.isArray(modelArrayList)
        ? modelArrayList
        : modelArrayList.split(",").map(Number)
      : "";

    const storeArray = Array.isArray(filter?.store)
      ? filter.store
      : [filter.store].filter(Boolean);

    getCarList({
      reg_no: filter?.vehicle_id || "",
      make: brandValues,
      lead_id: +id.path,
      model_id: modelArray.length ? modelArray : undefined,
      store: storeArray.length ? storeArray : undefined,
      is_website: filter?.is_website,
      is_motorpedia: filter?.is_motorpedia,
      is_suggested: 1,
      is_not_listed: filter?.is_not_listed,
      car_status: filter?.status || "1",
      dealerId: +filter?.dealerId || "",
      showroom_id: filter?.store,
    })
      .then((response) => {
        setData(response.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching filtered car list:", error);
      });
  };

  const fetchLeadCars = (page) => {
    const makeArray = Array.isArray(filter?.make)
      ? filter.make
      : [filter.make].filter(Boolean);
    const modelArray = filter.model_id
      ? Array.isArray(modelArrayList)
        ? modelArrayList
        : modelArrayList.split(",").map(Number)
      : "";

    const storeArray = Array.isArray(filter?.store)
      ? filter.store
      : [filter.store].filter(Boolean);

    getLeadCarList({
      leadId: id.path,
    })
      .then((response) => {
        setLeadCarList(response.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching filtered car list:", error);
      });
  };

  // const brands = async () => {
  //   try {
  //     const res = await publicApi.brandList();
  //     setBrandList(res?.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   brands();
  // }, []);

  useEffect(() => {
    setLeadCarList(leadCarList);
    setData(data);
  }, [data, leadCarList]);

  const applyFilter = () => {
    if (Object.values(filter).some((value) => value !== "")) {
      fetchFilteredCarList();
    }
  };

  useEffect(() => {
    fetchLeadCars();
    fetchFilteredCarList();
  }, [brandFilter.length]);

  const [isDetailPageVisible, setIsDetailPageVisible] = useState(false);

  const styles = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 600,
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    boxShadow: 2,
    p: 4,
    borderRadius: "8px",
    overflowY: "auto",
  };

  const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: "transparent",
    backdropFilter: "blur(0.9px)",
  }));

  const handleCheckboxChange = (checkboxValue) => {
    setFilter((prev) => {
      let updatedFilter = { ...prev };

      if (checkboxValue === "Website") {
        updatedFilter.is_website = updatedFilter.is_website === 0 ? 1 : 0;
        if (updatedFilter.is_website === 1) updatedFilter.is_not_listed = 0;
      }

      if (checkboxValue === "Motorpedia") {
        updatedFilter.is_motorpedia = updatedFilter.is_motorpedia === 0 ? 1 : 0;
        if (updatedFilter.is_motorpedia === 1) updatedFilter.is_not_listed = 0;
      }

      if (checkboxValue === "Not Listed") {
        updatedFilter.is_not_listed = updatedFilter.is_not_listed === 0 ? 1 : 0;
        if (updatedFilter.is_not_listed === 1) {
          updatedFilter.is_website = 0;
          updatedFilter.is_motorpedia = 0;
        }
      }

      return updatedFilter;
    });
  };

  return (
    <div className={style["container"]}>
      <div className={style["all-container"]}>
        <Box
          sx={{
            boxShadow: 2,
            borderRadius: "5px",
            border: "1px solid #121b5b",
          }}
          className={style["mid-container"]}
        >
          <Box
            sx={{
              backgroundColor: "#121b5b",
              padding: "5px",
              pl: "10px",
              color: "white",
              borderRadius: "5px",
            }}
          >
            <Typography variant="h6">Customer Details</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                padding: "0px 10px 0px 10px",
              }}
            >
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={style["status-section"]}
              >
                <Typography variant="subtitle1" className={style["label"]}>
                  Mobile:
                </Typography>

                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    color: "gray",
                  }}
                  variant="outlined"
                  value={preferences?.mobile || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={style["status-section"]}
              >
                <Typography variant="subtitle1" className={style["label"]}>
                  Name:
                </Typography>

                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={preferences?.name || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPreferences((prev) => ({ ...prev, name: value }));
                    setTempPreferences((prev) => ({ ...prev, name: value }));
                  }}
                  className={style["dealer-info-text"]}
                />
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={style["status-section"]}
              >
                <Typography variant="subtitle1" className={style["label"]}>
                  Email:
                </Typography>

                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={preferences?.email || ""}
                  onChange={(e) => {
                    const EmailValue = e.target.value;
                    setPreferences((prev) => ({ ...prev, email: EmailValue }));
                    setTempPreferences((prev) => ({
                      ...prev,
                      email: EmailValue,
                    }));
                  }}
                  className={style["dealer-info-text"]}
                />
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={style["status-section"]}
              >
                <Typography variant="subtitle1" className={style["label"]}>
                  Select Status:
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  select
                  value={preferences.statusId}
                  onChange={handleStatusChange}
                  variant="outlined"
                >
                  {buyerLeadStatus.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="subtitle1" className={style["label"]}>
                  FollowUp Date:
                </Typography>

                <DatePicker
                  size="small"
                  selected={
                    preferences.followDate ||
                    (customerList?.lastEnquiryDate
                      ? new Date(customerList.lastEnquiryDate)
                      : null)
                  }
                  sx={{
                    "& .react-datepicker-wrapper": {
                      width: "100%",
                    },
                    "& .MuiOutlinedInput-root": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 10px", // Adjust padding for better alignment
                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: "center", // Center text inside input
                    },
                  }}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()} // Restricts selection to today or future dates
                  customInput={
                    <TextField
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <FaCalendarAlt className={style["calendar-icon"]} />
                        ),
                      }}
                    />
                  }
                />
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={style["comment-section"]}
              >
                <Typography variant="subtitle1" className={style["label"]}>
                  Last Comment:
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={preferences.comment}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPreferences((prev) => ({ ...prev, comment: value }));
                    setTempPreferences((prev) => ({ ...prev, comment: value }));
                  }}
                  placeholder="Enter your last comment"
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Box>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* Button to open modal for previous comments */}
                <Button
                  sx={{
                    color: "primary",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => {
                    handleOpen();
                    commentHistory();
                  }}
                >
                  Comment History
                </Button>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  BackdropComponent={CustomBackdrop}
                >
                  <Box sx={styles}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* Left: Previous Comments */}

                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{ paddingLeft: "15px" }}
                      >
                        Comment History
                      </Typography>

                      {/* Right: Customer Name and Contact with Close Button */}
                      <Box display="flex" alignItems="center">
                        <Typography
                          id="modal-customer-info"
                          variant="body1"
                          sx={{ textAlign: "right", marginRight: 2 }}
                        >
                          Customer Name: <strong>{userName}</strong>
                          <br />
                          Contact Number: <strong>{userNumber}</strong>
                        </Typography>

                        {/* Close Button */}
                        <IconButton onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {/* Comments Section */}

                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, overflow: "hidden" }}
                    >
                      <div className={style["commentsContainer"]}>
                        {commentsHistory?.filter(
                          (commentObj) => commentObj.comment !== ""
                        ).length === 0 ? (
                          <div className={style["noCommentsMessage"]}>
                            No comments available
                          </div>
                        ) : (
                          commentsHistory
                            ?.filter((commentObj) => commentObj.comment !== "")
                            ?.map((commentObj, index) => {
                              const formattedDate = moment(
                                commentObj.createdDate,
                                "ddd, Do MMM. YYYY, h:mmA"
                              ).format("DD MMMM YYYY, h:mm A");

                              return (
                                <div
                                  key={commentObj.id}
                                  className={style["commentBlock"]}
                                >
                                  <Typography variant="body1">
                                    {commentObj.comment}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    className={style["dateText"]}
                                  >
                                    {formattedDate}
                                  </Typography>

                                  <Typography
                                    variant="body"
                                    className={style["dateText2"]}
                                  >
                                    <span className={style["updatedBy"]}>
                                      {commentObj.updatedBy}
                                    </span>{" "}
                                    -
                                    <span className={style["roleName"]}>
                                      {commentObj.roleName}
                                    </span>
                                  </Typography>

                                  {index !== commentsHistory.length - 1 && (
                                    <hr className={style["divider"]} />
                                  )}
                                </div>
                              );
                            })
                        )}
                      </div>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </Box>
            <Box className={style["save-button-section"]}>
              <Button
                size="small"
                onClick={handleBack}
                variant="contained"
                className={style["save-button"]}
                style={{ backgroundColor: "#121b5b" }}
              >
                Back
              </Button>
              <Button
                size="small"
                onClick={handleSave}
                variant="contained"
                className={style["save-button"]}
                style={{ backgroundColor: "#121b5b" }}
              >
                Save
              </Button>
              <Button
                size="small"
                onClick={handleSaveNext}
                variant="contained"
                className={style["save-button"]}
                style={{ backgroundColor: "#121b5b" }}
              >
                Save & Next
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: 2,
            borderRadius: "5px",
            border: "1px solid #121b5b",
          }}
          className={style["mid-container"]}
        >
          <CustomerPreference
            id={+id.path}
            brandFilter={brandFilter}
            setBrandFilter={setBrandFilter}
          />
        </Box>
      </div>
      <Box sx={{ padding: "10px" }}>
        <LeadInventory
          leadCarList={leadCarList}
          setLeadCarList={setLeadCarList}
        />
      </Box>
      <Box sx={{ padding: "10px" }}>
        <DetailTable
          id={+id.path}
          setLeadCarList={setLeadCarList}
          leadCarList={leadCarList}
          brandFilter={brandFilter}
          setBrandFilter={setBrandFilter}
          data={data}
          setData={setData}
          fetchLeadCars={fetchLeadCars}
          fetchFilteredCarList={fetchFilteredCarList}
        />
      </Box>

      <ToastContainer />
    </div>
  );
};

export default Step0;

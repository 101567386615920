import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Image } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { R } from "../../constants/resources";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Collapse } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import RateReviewIcon from "@mui/icons-material/RateReview";
const logo = localStorage.getItem("logo");
const DealerName = localStorage.getItem("dealerName");
const RoleName = localStorage.getItem("roleNamme")

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const MenuItems = [
  {
    text: "Inventory",
    route: "/car-management",
    icon: <DirectionsCarIcon />,
  },
  {
    text: "Buyer Leads",
    route: "/buyer-lead-management",
    icon: <AccountBoxIcon />,

    // Children: [
    //   {
    //     text: " Lead",
    //     route: "/lead-managment",
    //     icon: <ManageAccountsIcon />,
    //   },
    // ],
  },
  {
    text: "Seller Leads",
    route: "/seller-lead-management",
    icon: <SupervisedUserCircleIcon />,
  },
  {
    text: "Blogs",
    route: "/dealers-blogs",
    icon: <PostAddIcon />,
  },
  {
    text: "Enquiry",
    route: "/inquiry-management",
    icon: <SupportAgentIcon />,
  },
  {
    text: "Testimonials",
    route: "/testimonials",
    icon: <RateReviewIcon />,
  },
  {
    text: "FAQs",
    route: "/faqs-listing",
    icon: <RateReviewIcon />,
  },
  {
    text: "Dynamic Pages",
    route: "/daynamic-page-list",
    icon: <RateReviewIcon />,
  },
];

const RtoMenu = [
  {
    text: "RTO Management",
    route: "/rto-management",
    icon: R.ic_seller_blue,
  },
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#121b5b", // Change header background color here
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const Dealer_type = localStorage.getItem("dealer_type");
  const Bulk_lead_upload = localStorage.getItem("is_bulk_lead_upload");
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= theme.breakpoints.values.sm) {
        setOpen(false);
      }
    };

    const selectedStyle = {
      backgroundColor: "#007bff",
    };
    /******  40311a20-6151-4c4a-8338-3bf7bfc290b6  *******/
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [theme]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "end", textAlign: "center" }}>
            <Tooltip title="Account settings">
            {`${DealerName} - ${RoleName}`}
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openProfile ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openProfile}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem> */}
            <Divider />

            <MenuItem
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img width={180} src={logo} alt="logo" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {(Dealer_type === null
            ? MenuItems
            : Dealer_type === "1"
              ? MenuItems
              : RtoMenu
          ).map(({ text, route, icon, submenu, Children }, index) => (
            <React.Fragment key={text}>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={route}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    backgroundColor:
                      location.pathname === route ? "#121b5b" : "transparent",
                    color: location.pathname === route ? "#fff" : "#000",
                    "&:hover": {
                      color: "#000",
                      "& .hover-text": {
                        color: "#000",
                      },
                      "& .hover-icon": {
                        color: "#000",
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      className="hover-icon"
                      sx={{
                        color: location.pathname === route ? "#fff" : "#000",
                      }}
                    >
                      {icon}
                    </Box>
                    {/* <img src={icon} alt={`${text} icon`} /> */}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  {Children && open && (
                    <IconButton
                      onClick={() => setOpenChild((prev) => !prev)}
                      sx={{
                        ml: "auto",
                        "&:hover": {
                          "& .hover-icon": {
                            color: "#000",
                          },
                        },
                      }}
                    >
                      {openChild ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}
                </ListItemButton>
              </ListItem>
              {Children && open && (
                <Collapse in={openChild} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Children.map(({ text, route, icon }, index) => (
                      <ListItem key={text} disablePadding>
                        <ListItemButton
                          component={Link}
                          to={route}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 4,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <Box sx={{ color: "#121b5b" }}>{icon}</Box>
                          </ListItemIcon>
                          <ListItemText
                            primary={text}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

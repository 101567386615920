import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import JoditEditor from "jodit-react";
import BackButton from "../../../components/buttons/BackButton";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading";
import InputField from "../../../components/input-field/InputField";
import { IoEyeSharp, IoCloseSharp } from "react-icons/io5";
import { blogs } from "../../../api/blogs";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { dealerId } from "../../../constants/resources";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CreateBlogs() {
  const [fileName, setFileName] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  console.log("thumbnailUrl", thumbnailUrl);
  const [bannerUrl, setBannerUrl] = useState(null);

  const { total } = useSelector((state) => state.buyerLead);
  const dealer_id = dealerId;
  const location = useLocation();
  const { id } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useRef(null);

  const [formData, setFormData] = useState({
    title: "",
    author: "",
    tags: "",
    description: "",
    banner: null,
    thumbnail: null,
    slugs: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await blogs.getDealerBlogs({ blog_id: id, all: 1 });
        const data = res.data;
        console.log("blogs-respnse --->", data);

        const blog = data[0];
        setFormData({
          title: blog?.title || "",
          author: blog?.author || "",
          slugs:
            formateSpacesWithDash(blog?.slugs) ||
            formateSpacesWithDash(blog?.title),
          tags: blog?.tags || "",
          description: blog?.description || "",
          banner: blog?.blog_banner || "",
          thumbnail: blog?.blog_logo || "",
        });
        // Set the URLs for previewing the images
        if (blog?.blog_logo) {
          setThumbnailUrl(blog.blog_logo);
          console.log(blog.blog_logo);
          setFileName(blog.blog_logo.split("/").pop()); // Extract file name from URL
        }
        if (blog?.blog_banner) {
          setBannerUrl(blog.blog_banner);
          console.log(blog.blog_banner);
          setBannerName(blog.blog_banner.split("/").pop()); // Extract file name from URL
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
    }));

    if (name === "thumbnail") {
      setFileName(file?.name || "");
      setThumbnailUrl(file ? URL.createObjectURL(file) : null);
    } else if (name === "banner") {
      setBannerName(file?.name || "");
      setBannerUrl(file ? URL.createObjectURL(file) : null);
    }
  };

  const handleRemoveFile = (fileType) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fileType]: null,
    }));

    if (fileType === "thumbnail") {
      setFileName("");
      setThumbnailUrl(null);
    } else if (fileType === "banner") {
      setBannerName("");
      setBannerUrl(null);
    }
  };

  const formateSpacesWithDash = (str) => {
    if (!str) return "";
    return str.trim().replace(/\s+/g, "-").toLowerCase();
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    const status = 2;
    const formDatas = new FormData();
    if (status) {
      formDatas.append("status", status);
    }
    if (id) {
      formDatas.append("dealer_blogs_id", id);
    }
    Object.keys(formData).forEach((key) => {
      formDatas.append(key, formData[key]);
    });

    try {
      await blogs.saveDealerBlogs(
        { dealer_id: dealer_id },
        formDatas,
        id,
        status
      );
      toast.success("Saved successfully!", {
        autoClose: 3000,
      });
      navigate("/dealers-blogs");
    } catch (error) {
      toast.error("Failed to save blog!", {
        autoClose: 3000,
      });
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "slugs") {
      formattedValue = formateSpacesWithDash(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));

    if (name === "title" && !id) {
      setFormData((prevData) => ({
        ...prevData,
        slugs: formateSpacesWithDash(value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const status = 1;
    const requiredFields = ["title", "author", "tags"];
    const isValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (!isValid) {
      alert("Please fill in all required fields");
      return;
    }

    const formDatas = new FormData();
    if (status) {
      formDatas.append("status", status);
    }
    if (id) {
      formDatas.append("dealer_blogs_id", id);
    }

    // Capture the timestamp
    const published_date = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    formDatas.append("published_date", published_date);

    Object.keys(formData).forEach((key) => {
      formDatas.append(key, formData[key]);
    });

    try {
      await blogs.saveDealerBlogs(
        { dealer_id: dealer_id },
        formDatas,
        status,
        published_date
      );
      toast.success("Saved successfully!", {
        autoClose: 3000,
      });
      navigate("/dealers-blogs");
    } catch (error) {
      toast.error("Failed to save blog!", {
        autoClose: 3000,
      });
      console.error(error);
    }
  };

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",

          padding: "15px ",
        }}
      >
        <BackButton
          onClick={() => {
            navigate("/dealers-blogs");
          }}
        />
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Edit Blog
        </Typography>
      </Box>

      <Box className={style["form-wrapper"]}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Title"
                variant="outlined"
                name="title"
                placeholder="Enter the title"
                onChange={handleChange}
                value={formData.title}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Slug"
                variant="outlined"
                name="slugs"
                placeholder="Enter the slug"
                onChange={handleChange}
                value={formData.slugs}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "20px",
              }}
              sm={6}
              md={6}
            >
              <TextField
                size="small"
                fullWidth
                label="Author"
                variant="outlined"
                name="author"
                placeholder="Enter the author"
                onChange={handleChange}
                value={formData.author}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              marginTop: "20px",
            }}
            item
            xs={12}
          >
            <Typography variant="h6">Description</Typography>
            <JoditEditor
              ref={editor}
              config={{ width: "100%", height: "420px" }}
              value={formData.description}
              onBlur={(newContent) =>
                setFormData((val) => ({ ...val, description: newContent }))
              }
            />
          </Grid>
          <Grid
            container
            marginTop={2}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "300px",
                  width: "100%",
                  border: "2px dashed #ccc",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f9f9f9",
                  position: "relative",
                  transition: "all 0.3s",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                    borderColor: "#007bff",
                  },
                }}
              >
                {!thumbnailUrl ? (
                  <>
                    <label
                      htmlFor="thumbnailInput"
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ mb: 1 }}
                      >
                        Upload Thumbnail
                      </Typography>
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          backgroundColor: "#121b5b",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#121b5b" },
                        }}
                      >
                        Browse File
                      </Button>
                    </label>
                    <input
                      id="thumbnailInput"
                      type="file"
                      onChange={handleFileChange}
                      name="thumbnail"
                      style={{
                        display: "none",
                      }}
                    />
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={thumbnailUrl}
                      alt="Thumbnail Preview"
                      sx={{
                        maxHeight: "150px",
                        maxWidth: "100%",
                        borderRadius: "5px",
                        objectFit: "contain",
                      }}
                    />
                    <Box mt={2} display="flex" gap={1}>
                      <IconButton
                        component="a"
                        href={thumbnailUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#0056b3" },
                        }}
                      >
                        <IoEyeSharp size={20} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleRemoveFile("thumbnail")}
                        sx={{
                          backgroundColor: "#dc3545",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#c82333" },
                        }}
                      >
                        <IoCloseSharp size={20} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "300px",
                  width: "100%",
                  border: "2px dashed #ccc",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f9f9f9",
                  position: "relative",
                  transition: "all 0.3s",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                    borderColor: "#007bff",
                  },
                }}
              >
                {!bannerUrl ? (
                  <>
                    <label
                      htmlFor="bannerInput"
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ mb: 1 }}
                      >
                        Upload Banner
                      </Typography>
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          backgroundColor: "#121b5b",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#121b5b" },
                        }}
                      >
                        Browse File
                      </Button>
                    </label>
                    <input
                      id="bannerInput"
                      type="file"
                      onChange={handleFileChange}
                      name="banner"
                      style={{
                        display: "none",
                      }}
                    />
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={bannerUrl}
                      alt="Banner Preview"
                      sx={{
                        maxHeight: "150px",
                        maxWidth: "100%",
                        borderRadius: "5px",
                        objectFit: "contain",
                      }}
                    />
                    <Box mt={2} display="flex" gap={1}>
                      <IconButton
                        component="a"
                        href={bannerUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#0056b3" },
                        }}
                      >
                        <IoEyeSharp size={20} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleRemoveFile("banner")}
                        sx={{
                          backgroundColor: "#dc3545",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#c82333" },
                        }}
                      >
                        <IoCloseSharp size={20} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid sx={{ marginTop: "20px" }} item xs={12} sm={6} md={4}>
            <TextField
              size="small"
              fullWidth
              label="Tags"
              variant="outlined"
              name="tags"
              placeholder="Enter the tags"
              onChange={handleChange}
              value={formData.tags}
            />
          </Grid>
          <Grid
            sx={{ marginTop: "10px" }}
            item
            xs={12}
            container
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                sx={{
                  color: "#121b5b",
                  border: "1px solid #121b5b",
                  "&:hover": {
                    backgroundColor: "#121b5b",
                    color: "#fff",
                  },
                }}
                variant="outlined"
                onClick={handleSaveDraft}
                className={style["form-saved"]}
              >
                Save as Draft
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: "#121b5b",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#121b5b",
                    color: "#fff",
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
                className={style["form-published"]}
              >
                Save & Publish
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

export default CreateBlogs;

import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Step1 from "./step1/index.jsx";
import Step2 from "./step2/index.jsx";
import { Box, Tabs, Tab } from "@mui/material";
import Step0 from "./step0/index.jsx";

const Preference = () => {
  const id = useParams();
  console.log("id ==>", id.path);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(state?.currentStep || 0);
  const [dealerId, setDealerId] = useState(state?.dealerId || null);

  useEffect(() => {
    if (state == null) return;
    if (state && state?.currentStep) {
      dispatch(Step0(currentStep));
      dispatch(Step1(currentStep));
      dispatch(Step2(currentStep));
    } else {
      dispatch(Step0(state));
      dispatch(Step1(state));
      dispatch(Step2(state));
      setDealerId(state);
    }

    // return () => {
    //   window.history.replaceState(null, "");
    //   dispatch(resetDealer());
    // };
  }, [state]);

  const steps = [
    { id: "customer_preference", title: "Customer Status" },
    { id: "customer_preferences", title: "Customer Preferences" },
    // { id: "assign_dealers", title: "Assign Dealer" },
  ];

  const handleChange = (event, newValue) => {
    setCurrentStep(newValue);
  };

  return (
    <div className={style.container}>
      {/* <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <Tabs value={currentStep} textColor="#121b5b" onChange={handleChange}>
          {steps.map((step) => (
            <Tab
              sx={{
                "&.Mui-selected": {
                  color: "#121b5b",
                },
                "&:hover": {
                  color: "#121b5b",
                },
              }}
              key={step.id}
              label={step.title}
            />
          ))}
        </Tabs>
      </Box> */}
      <div className={style["form-container"]}>
        {currentStep === 0 && <Step0 setCurrentStep={setCurrentStep} id={id} />}
        {currentStep === 1 && <Step1 setCurrentStep={setCurrentStep} id={id} />}
        {/* {currentStep === 2 && (
          <Step2
            setCurrentStep={setCurrentStep}
            id= {id}
          />
        )} */}
      </div>
    </div>
  );
};

export default Preference;

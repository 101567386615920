import { ADD_FAQS_PAGE, RESET_FAQS, GET_FAQS_LIST, GET_DYNAMIC_PAGE, ADD_DYNAMIC_PAGE } from "../types";

const initalState = {
    faqData: {
        faqsDetails: {},
        dynamiContent: {}
    },
    faqsList: [],
}

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_FAQS_PAGE:
            return {
                ...state,
                faqData: { ...state?.faqData, faqsDetails: payload }
            }


        case GET_FAQS_LIST:
            return {
                ...state, faqsList: payload?.data
            };


        case ADD_DYNAMIC_PAGE:
            return {
                ...state,
                faqData: { ...state?.faqData, dynamiContent: payload }
            }

        case RESET_FAQS:
            return {
                ...state,
                faqData: {
                    faqsDetails: {},
                    dynamiContent : {}
                },
            };

        default:
            return state;
    }
}
import React, { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import style from "./customerLead.module.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { buyerApi } from "../../../../api/buyerLeadApi";
import { useNavigate } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast
import { R } from "../../../../constants/resources";
import { getValue, styled } from "@mui/system";
import { FaUser, FaEnvelope, FaPhone, FaCalendarAlt } from "react-icons/fa";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Modal, Typography, Box, Backdrop } from "@mui/material";
// import CommentsTable from "./CommentsTable";

export default function LeadTable({
  data = [],
  customer,
  assignFilter = "all",
  setAssignedToBtn,
  setLeadIdAssignedTo,
}) {
  const [details, setDetails] = useState({});
  const [commentRowId, setCommentRowId] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [temporaryStatus, setTemporaryStatus] = useState({});
  const [tempFollowUpDate, setTmpFollowUpDate] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [commentsHistory, setCommentHistory] = useState([]);
  const [userName, setUserName] = useState("");
  const [userNumber, setContactNumber] = useState("");
  const [checkedRows, setCheckedRows] = useState({});
  const [buyerLeadStatus, setbuyerLeadStatus] = useState([]);
  // const [results, setResults] = useState([]);
  const [hasAssignedTo, setHasAssignedTo] = useState([]);
  const [selectTeleCaller, setSelectTeleCaller] = useState({});
  const [selectPOC, setSelectPOC] = useState({});
  const [salesPoc, setSalesPoc] = useState([]);
  const [taleCaller, setTaleCaller] = useState([]);
  const [roleId, setRoleId] = useState(null);

  useEffect(() => {
    const checkedKeys = Object.keys(checkedRows).filter(
      (key) => checkedRows[key]
    );
    setLeadIdAssignedTo(checkedKeys);
  }, [data, assignFilter, checkedRows]);

  const buyerLeadStstus = async () => {
    try {
      const res = await buyerApi.getBuyerLeadStatus();
      if (res) {
        setbuyerLeadStatus(res.data);
      }
    } catch (err) {
      console.error("Failed to fetch customer info:", err);
    }
  };

  const handleAssignedToSelect = async () => {
    const getDealerId = localStorage.getItem("dealerId");
    try {
      const res = await buyerApi.getDealerSubUser();
      if (res?.status) {
        const taleCaller = res?.data.filter((user) => user.parentRoleId == 65);
        const SalePoc = res?.data.filter((user) => user.parentRoleId == 64);

        setTaleCaller(taleCaller);
        setSalesPoc(SalePoc);
      }
    } catch (error) {
      console.error("Error in get-dealers-sub-user:", error);
    }
  };

  const handleDateChange = (date, row) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [row.id]: {
        ...prevDetails[row.id],
        followDate: date,
      },
    }));

    const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    setTmpFollowUpDate(formattedDate);
  };

  const commentHistory = async (rowDetails) => {
    console.log("rowDetails", rowDetails);
    try {
      setUserName(rowDetails.name);
      setContactNumber(rowDetails.mobile);

      const res = await buyerApi.getCustomerCommentHistory(rowDetails.leadId);
      setCommentHistory(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    commentHistory();
    buyerLeadStstus();
    handleAssignedToSelect();
  }, []);

  useEffect(() => {
    const storedRoleId = localStorage.getItem("roleId");
    setRoleId(storedRoleId);
  }, []);

  const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
  };

  const fetchAssignUser = async (value, row, name) => {
    const getLeadId = String(row.original.leadId);
    let assignedType;
    if (name === "teleCaller") {
      assignedType = "telecaller";
    } else if (name === "poc") {
      assignedType = "poc";
    }

    const params = {
      userId: [value],
      assign_to: assignedType,
      leads: [getLeadId],
    };

    try {
      const result = await buyerApi.assignUser(params);
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error(
        "Error ons Lead Table assigned to select button api: ",
        error
      );
      toast.error(error.response.data.message);
    }
  };

  const handleStatusChange = (event, row) => {
    const statusId = event.target.value;
    console.log("bjfbdjfdjfb ", statusId);

    setTemporaryStatus((prevStatus) => ({
      ...prevStatus,
      [row.id]: statusId,
    }));
  };

  const handleTeleCallerChange = (event, row) => {
    const teleCallerId = event.target.value;
    fetchAssignUser(teleCallerId, row, "teleCaller");
    setSelectTeleCaller((prev) => ({
      ...prev,
      [row.original.leadId]: teleCallerId,
    }));
  };

  const handlePocChange = (event, row) => {
    const pocId = event.target.value;
    fetchAssignUser(pocId, row, "poc");
    setSelectPOC((prev) => ({ ...prev, [row.original.leadId]: pocId }));
  };

  const handleNext = async (rowDetails) => {
    try {
      navigate(`/lead/customer-preferences/${rowDetails.leadId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveClick = async (rowDetails, rowIndex) => {
    try {
      const updatedComment = commentValue.trim();
      const statusName = temporaryStatus;
      const statusId = temporaryStatus[rowIndex];

      console.log("statusIdstatusId ", statusId);

      if (!updatedComment && !tempFollowUpDate && !statusId) {
        toast.error(
          "Required data is missing. Please fill in at least one field."
        );
        return;
      }

      setDetails((prevDetails) => ({
        ...prevDetails,
        [rowDetails.id]: {
          ...prevDetails[rowDetails.id],
          lastComment: updatedComment,
          statusName: statusName,
          statusId: statusId,
          lastEnquiryDate: details[rowDetails.id]?.followDate,
        },
      }));

      const followDate = tempFollowUpDate
        ? moment(tempFollowUpDate).format("YYYY-MM-DD HH:mm:ss")
        : rowDetails?.followDate;

      if ([2, 1, 3, 4, 5, 6].includes(+statusId)) {
        if (!followDate) {
          toast.error("Follow-up date is required");
          return;
        }

        const today = moment().startOf("day");
        const followDateMoment = moment(followDate, "YYYY-MM-DD HH:mm:ss");
        if (followDateMoment.isBefore(today)) {
          toast.error("Follow-up date cannot be in the past.");
          return;
        }
      }

      const payload = {
        customerId: rowDetails?.customerId,
        comment: updatedComment,
        leadId: rowDetails?.leadId, // Added leadId here
        ...(tempFollowUpDate && {
          tempFollowUpDate,
          followDate: tempFollowUpDate,
        }),
        ...(statusId && { statusId: +statusId }),
      };

      await buyerApi.addUpdateCustomerInfo(payload);
      // customer();
      toast.success("Data saved successfully!");
      setTemporaryStatus((prevStatus) => ({
        ...prevStatus,
        [rowDetails.id]: undefined,
      }));
      setCommentRowId(null);
      setCommentValue("");
      setTmpFollowUpDate(null);
    } catch (error) {
      toast.error("Failed to save data.");
      console.log(error);
    }
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 2,
    p: 4,
    borderRadius: "8px",
    overflowY: "auto",
  };

  const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: "transparent",
    backdropFilter: "blur(0.5px)",
  }));

  const handleSelectAll = (isChecked) => {
    setAssignedToBtn(false);
    const updatedRows = {};
    if (assignFilter === "all") {
      data.forEach((row) => {
        updatedRows[row.leadId] = isChecked;
      });
    } else if (assignFilter === "assigned") {
      data.forEach((row) => {
        updatedRows[row.leadId] = false;
      });
    } else if (assignFilter === "unassigned") {
      data.forEach((row) => {
        updatedRows[row.leadId] = true;
      });
    }
    setCheckedRows(updatedRows);
  };

  const handleCheckboxChange = (rowId, isChecked) => {
    setAssignedToBtn(false);
    setCheckedRows((prev) => ({
      ...prev,
      [rowId]: isChecked,
    }));
  };

  const isAllSelected =
    data.length > 0 && data.every((row) => checkedRows[row.leadId]);
  const isSomeSelected =
    data.some((row) => checkedRows[row.leadId]) && !isAllSelected;

  const columns = [
    {
      accessorKey: "check",
      header: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={isSomeSelected}
          onChange={(e) => handleSelectAll(e.target.checked)}
          inputProps={{ "aria-label": "Select all rows" }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={!!checkedRows[row.original.leadId]} // Bind to leadId
          onChange={
            (e) => handleCheckboxChange(row.original.leadId, e.target.checked) // Pass leadId
          }
          inputProps={{ "aria-label": `Checkbox for row ${row.id}` }}
        />
      ),
    },
    // {
    //   accessorKey: "sno",
    //   header: "S.No",
    //   cell: (info) => info.row.index + 1 + ".",
    // },

    {
      accessorKey: "customerDetails",
      header: "Customer Details",
      cell: ({ row }) => {
        const { mobile, email, name, createdDate, otpVerified } = row.original;
        const isOtpVerified = otpVerified == 1 ? true : false;
        return (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <FaUser className="text-blue-500" />
              {name ? name : "Guest"}
            </div>

            {mobile && (
              <div className="flex items-center gap-4">
                <FaPhone className={style["text-purple-500"]} />
                {mobile}
                {isOtpVerified && (
                  <Tooltip title="Verified">
                    {/* <div className={style["tool-tip"]}> */}
                    <RiVerifiedBadgeFill className={style["text-green-500"]} />
                    {/* </div> */}
                  </Tooltip>
                )}
              </div>
            )}

            {email && (
              <div className="flex items-center gap-4">
                <FaEnvelope className="text-green-500" /> {email}
              </div>
            )}

            {createdDate && (
              <div className="flex items-center gap-4">
                <FaCalendarAlt className="text-orange-500" />{" "}
                {moment(createdDate).format("DD-MM-YYYY")}
              </div>
            )}
          </div>
        );
      },
    },

    {
      accessorKey: "leadRating",
      header: "Rating",
      cell: ({ getValue }) => {
        const rating = getValue();
        if (rating) {
          return rating.charAt(0).toUpperCase() + rating.slice(1).toLowerCase();
        }
        return "NA";
      },
    },

    {
      accessorKey: "statusName",
      header: "Status",
      cell: ({ row }) => {
        const selectedStatusId =
          temporaryStatus[row.id] || row.original.statusId;
        return (
          <select
            value={selectedStatusId}
            onChange={(e) => handleStatusChange(e, row)}
          >
            {buyerLeadStatus.map((status) => (
              <option key={status.id} value={status.id}>
                {status.statusName}
              </option>
            ))}
          </select>
        );
      },
    },

    {
      accessorKey: "assignToTelecaller",
      header: "Telecaller",
      cell: ({ row }) => {
        const selectedTeleCaller =
          selectTeleCaller[row.original.leadId] ||
          row.original.assignToTelecaller;
        const isValidTeleCaller = taleCaller.find(
          (teleCaller) => teleCaller.id == selectedTeleCaller
        );
        const isDisabled = roleId !== "57" && roleId !== "63";

        return (
          <select
            className={style["telecaller-select"]}
            value={isValidTeleCaller ? selectedTeleCaller : ""}
            onChange={(e) => handleTeleCallerChange(e, row)}
            disabled={isDisabled} // Disable if isDisabled is true
          >
            <option value="" disabled>
              Select
            </option>
            {taleCaller.map((status) => (
              <option key={status.id} value={status.id}>
                {status.firstName}
              </option>
            ))}
          </select>
        );
      },
    },

    {
      accessorKey: "assignToPoc",
      header: "Sales POC",
      cell: ({ row }) => {
        const selectedStatusId =
          selectPOC[row.original.leadId] || row.original.assignToPoc;

        const isValidStatus = salesPoc.some(
          (status) => status.id == selectedStatusId
        );

        const isDisabled = roleId !== "57" && roleId !== "63";

        return (
          <select
            className={style["telecaller-select"]}
            value={isValidStatus ? selectedStatusId : ""}
            onChange={(e) => handlePocChange(e, row)}
            disabled={isDisabled}
          >
            <option value="" disabled>
              Select
            </option>
            {salesPoc.map((status) => (
              <option key={status.id} value={status.id}>
                {status.firstName}
              </option>
            ))}
          </select>
        );
      },
    },

    {
      accessorKey: "followDate",
      header: "FollowUp Date",
      cell: ({ row }) => {
        const originalDate = row.original.followDate
          ? new Date(row.original.followDate)
          : null;
        const selectedDate = details[row.id]?.followDate || originalDate;

        return (
          <div className={style.datePickerWrapper}>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => handleDateChange(date, row)}
              dateFormat="DD-MM-yyyy"
              minDate={new Date()} // Restricts selection to today or future dates
              customInput={
                <div className={style.customDateInput}>
                  {selectedDate
                    ? moment(selectedDate).format("DD-MM-yyyy")
                    : "Select Date"}{" "}
                  <FaCalendarAlt className={style.calendarIcon} />
                </div>
              }
            />
          </div>
        );
      },
    },

    {
      accessorKey: "commentsLink",
      header: "Comments",
      cell: ({ row }) => {
        const isEditing = commentRowId === row.id;

        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* Button to open modal for previous comments */}
            <Button
              onClick={() => {
                handleOpen();
                commentHistory(row.original);
              }}
            >
              Comment History
            </Button>

            {/* Add comment button or textarea for comment input */}
            {!isEditing && (
              <button
                className={style.addCommentButton}
                onClick={() => {
                  setCommentRowId(row.id);
                  setCommentValue("");
                }}
              >
                + Add Comment
              </button>
            )}

            {isEditing && (
              <textarea
                className={style.commentInput}
                value={commentValue}
                onChange={handleCommentChange}
                placeholder="Enter latest comment"
                autoFocus
                onFocus={(e) =>
                  e.target.setSelectionRange(
                    commentValue.length,
                    commentValue.length
                  )
                }
                maxLength={200}
              />
            )}

            {/* Modal to display previous comments */}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropComponent={CustomBackdrop}
            >
              <Box sx={styles}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* Left: Previous Comments */}

                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{ paddingLeft: "15px" }}
                  >
                    Comment History
                  </Typography>

                  {/* Right: Customer Name and Contact with Close Button */}
                  <Box display="flex" alignItems="center">
                    <Typography
                      id="modal-customer-info"
                      variant="body1"
                      sx={{ textAlign: "right", marginRight: 2 }}
                    >
                      Customer Name: <strong>{userName}</strong>
                      <br />
                      Contact Number: <strong>{userNumber}</strong>
                    </Typography>

                    {/* Close Button */}
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                {/* Comments Section */}

                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, overflow: "hidden" }}
                >
                  <div className={style["commentsContainer"]}>
                    {commentsHistory?.filter(
                      (commentObj) => commentObj.comment !== ""
                    ).length === 0 ? (
                      <div className={style["noCommentsMessage"]}>
                        No comments available
                      </div>
                    ) : (
                      commentsHistory
                        ?.filter((commentObj) => commentObj.comment !== "")
                        ?.map((commentObj, index) => {
                          const formattedDate = moment(
                            commentObj.createdDate,
                            "ddd, Do MMM. YYYY, h:mmA"
                          ).format("DD MMMM YYYY, h:mm A");

                          return (
                            <div
                              key={commentObj.id}
                              className={style["commentBlock"]}
                            >
                              <Typography variant="body1">
                                {commentObj.comment}
                              </Typography>

                              <Typography
                                variant="body2"
                                className={style["dateText"]}
                              >
                                {formattedDate}
                              </Typography>

                              <Typography
                                variant="body"
                                className={style["dateText2"]}
                              >
                                <span className={style["updatedBy"]}>
                                  {commentObj.updatedBy}
                                </span>{" "}
                                -
                                <span className={style["roleName"]}>
                                  {commentObj.roleName}
                                </span>
                              </Typography>

                              {index !== commentsHistory.length - 1 && (
                                <hr className={style["divider"]} />
                              )}
                            </div>
                          );
                        })
                    )}
                  </div>
                </Typography>

                {/* <Typography  id="modal-modal-description" sx={{ mt: 2,overflow: "hidden" }}>
                  <div className={style["commentsContainer"]}>
                    {commentsHistory?.filter(
                      (commentObj) => commentObj.comment !== ""
                    ).length === 0 ? (
                      <div className={style["noCommentsMessage"]}>
                        No comments available
                      </div>
                    ) : (
                      commentsHistory
                        ?.filter((commentObj) => commentObj.comment !== "")
                        ?.map((commentObj, index) => {
                          const formattedDate = moment(
                            commentObj.createdDate,
                            "ddd, Do MMM. YYYY, h:mmA"
                          ).format("DD MMMM YYYY, h:mm A");

                          return (
                            <div
                              key={commentObj.id}
                              className={style["commentBlock"]}
                            >
                              <Typography variant="body1">
                                {commentObj.comment}
                              </Typography>

                              <Typography
                                variant="body2"
                                className={style["dateText"]}
                              >
                                <strong></strong> {formattedDate}
                              </Typography>

                              {index !== commentsHistory.length - 1 && (
                                <hr className={style["divider"]} />
                              )}
                            </div>
                          );
                        })
                    )}
                  </div>
                </Typography> */}
              </Box>
            </Modal>
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: ({ row }) => (
        <div>
          <button
            className={style.processButton}
            onClick={() => handleSaveClick(row.original, row.index)}
          >
            Save
          </button>
          {/* <button
            className={style.processButton}
            onClick={() => handleNext(row.original)}
          >
            Preferences
          </button> */}
          <img
            src={R.ic_preference_logo}
            alt="ic_edit"
            onClick={() => handleNext(row.original)}
            className={style["pref-button"]}
            title="Customer Preferences"
          />
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div
      className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
    >
      <table
        className={`${style["table-wrapper"]} table table-border table-hover`}
        cellSpacing={"5px"}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup?.id} className={style["table-header-wrapper"]}>
              {headerGroup?.headers?.map((header) => (
                <th key={header?.id} className={style["table-header"]}>
                  {flexRender(
                    header?.column?.columnDef?.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {data.length > 0 ? (
            table?.getRowModel()?.rows?.map((row) => (
              <tr key={row?.id} className={style["table-row"]}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(
                      cell?.column?.columnDef?.cell,
                      cell?.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className={style["no-data-row"]}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ToastContainer /> {/* Include ToastContainer here */}
      {/* <React.Fragment>
        <Dialog
          open={confirmAssignedDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment> */}
    </div>
  );
}

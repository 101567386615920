export const INITIALIZE_THE_LOADER = "INITIALIZE_THE_LOADER";
export const STOP_THE_LOADER = "STOP_THE_LOADER";
export const INITIALIZE_THE_PAGE_LOADER = "INITIALIZE_THE_PAGE_LOADER";
export const STOP_THE_PAGE_LOADER = "STOP_THE_PAGE_LOADER";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const EDIT_BUYER_LEAD = "EDIT_BUYER_LEAD";
export const GET_BUYER_LEAD = "GET_BUYER_LEAD";
export const EDIT_SELLER_LEAD = "EDIT_SELLER_LEAD";
export const GET_SELLER_LEAD = "GET_SELLER_LEAD";
export const GET_CAR_LIST = "GET_CAR_LIST";
export const CREATE_CAR_DETAIL = "CREATE_CAR_DETAIL";
export const EDIT_CAR_DETAIL = "EDIT_CAR_DETAIL";
export const EDIT_INQUIRE_LIST = "EDIT_INQUIRE_LIST";
export const GET_DEALER_ADDRESS = "GET_DEALER_ADDRESS";
export const GET_DEALER_USERS = "GET_DEALER_USERS";
export const GET_ALL_DEALERS = "GET_ALL_DEALERS";
export const GET_ALL_PURCHESAR = "GET_ALL_PURCHESAR";
export const GET_ALL_SOURCE = "GET_ALL_SOURCE";
export const ADD_FAQS_PAGE="ADD_FAQS_PAGE";
export const GET_FAQS_LIST = "GET_FAQS_LIST";
export const RESET_FAQS="RESET_FAQS";
export const ADD_DYNAMIC_PAGE="ADD_DYNAMIC_PAGE";
export const GET_DYNAMIC_PAGE="GET_DYNAMIC_PAGE";


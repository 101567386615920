import React, { useState } from "react";
import style from "./sellerTable.module.scss";
import moment from "moment";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Paper from "@mui/material/Paper";
import { R } from "../../../../constants/resources";
import { useNavigate } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Chip } from "@mui/material";

export default function SellerTable({ data }) {
  const navigate = useNavigate();

  const columns = [
    { accessorKey: "seller_name", header: "Seller Name" },
    { accessorKey: "phone_no", header: "Phone No." },
    { accessorKey: "make", header: "Make" },
    { accessorKey: "model", header: "Model" },
    { accessorKey: "variant", header: "Variant" },
    { accessorKey: "selling_price", header: "Selling Price" },
    { accessorKey: "quote_price", header: "Our Price" },
    // { accessorKey: "leadStatus", header: "Status" },
    // { accessorKey: "action", header: "Action" },
  ];
  const [view, setView] = useState(window.innerWidth > 1200 ? "list" : "grid");

  const handleViewChange = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const Status = ({ value }) => {
    let backgroundColor;
    let color;
    switch (value) {
      case "ACTIVE":
        backgroundColor = "#00B00D";
        color = "#fff";
        break;
      case "PENDING":
      case "CONFIRMED":
        backgroundColor = "#F7DC6F";
        color = "#000000";
        break;
      case "SOLD":
        backgroundColor = "#F08080";
        color = "#000000";
        break;
      case "Done":
        backgroundColor = "#32CD32";
        color = "#000000";
        break;
      default:
        backgroundColor = "transparent";
        color = "#000";
    }

    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: backgroundColor,
          color: color,
          borderRadius: "15px",
          padding: "4px",
          paddingTop: "6px",
          display: "inline-block",
          textAlign: "center",
        }}
      >
        {value}
      </Chip>
    );
  };

  const onEditClick = (data) => {
    navigate("/edit-seller-lead", { state: data });
  };

  const Action = (data) => (
    <div className={style["action-container"]}>
      <img
        src={R.ic_edit_pen}
        alt="ic_edit"
        onClick={() => onEditClick(data)}
      />
    </div>
  );

  return (
    <>
      <IconButton
        aria-label="toggle view"
        onClick={handleViewChange}
        sx={{
          ml: "auto",
          display: "flex",
          justifyContent: "flex-end",
          display: { xs: "none", md: "block" },
        }}
      >
        {view === "grid" ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>
      <div
        className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
      >
        <>
          {view === "grid" ? (
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {data?.map((car, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      margin: 1,
                      ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid sx={{ width: "100px" }} item>
                          <Typography
                            variant="body2"
                            component="div"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            {car?.seller_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="p" color="textSecondary">
                            <Status value={car?.leadStatus} />
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "8px",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Phone No
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.phone_no}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Make
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.make}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Model
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.model}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Variant
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.variant}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Selling Price
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.selling_price}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Quote Price
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.quote_price}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          ></Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <List>
              <TableContainer
                component={Paper}
                sx={{ width: "99%", margin: "1px 10px 5px 6px" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#f5f5f5",
                      "& .MuiTableCell-root": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell align="left">Seller Name</TableCell>
                      <TableCell align="left">Phone No.</TableCell>
                      <TableCell align="left">Make</TableCell>
                      <TableCell align="left">Model</TableCell>
                      <TableCell align="left">Variant</TableCell>
                      <TableCell align="left">Selling Price</TableCell>
                      <TableCell align="left">Our Price</TableCell>
                      <TableCell align="left">Lead Date </TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((car, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell sx={{ ml: 2 }}>{car?.seller_name}</TableCell>
                        <TableCell>{car?.phone_no}</TableCell>
                        <TableCell>{car?.make}</TableCell>
                        <TableCell>{car?.model}</TableCell>
                        <TableCell>{car?.variant}</TableCell>
                        <TableCell>{car?.selling_price}</TableCell>
                        <TableCell>{car?.quote_price}</TableCell>
                        <TableCell>{moment(car?.created_ts).format("DD-MM-YYYY")}</TableCell>
                        <TableCell>
                          <Status value={car?.leadStatus} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </List>
          )}
        </>
      </div>
    </>
  );
}

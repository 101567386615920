import React, { useState, useEffect } from "react";
import style from "./style.module.scss"
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { DynamicList } from "./table/table";
import daynamicpage from "../../api/daynamicpage";

export function DaynamicPages() {

  const [value, setValues] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const navigate = useNavigate();

  const getDynamicPages = async () => {
    try {
      const res = await daynamicpage.getDynamicPage();
      setValues(res?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    if (!searchText) {
      setFilterData(value);
      return;
    }

    let filterd = value.filter((item) =>
    (item?.page_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.city_name?.toLowerCase().includes(searchText.toLowerCase()))
    );

    setFilterData(filterd);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, value]);

  useEffect(() => {
    getDynamicPages();
  }, [])


  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Page List
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Search parent page"}
            handleChange={handleChange}
          />

          <SearchBar
            placeholder={"Search City name"}
            handleChange={handleChange}
          />

        </div>
        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-blogs-button"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Pages"}
            fontSize={"1rem"}
            linkTo={"/create-daynamic-page"}
            onClick={() => navigate("/create-daynamic-page")}
          />
        </div>
      </div>
      <DynamicList
        value={filterData}
        setValues={setValues}
        totalCount={totalCount} // Pass total count to BlogTable
        setTotalCount={setTotalCount} // Pass setTotalCount to update from table
      />
    </div>
  );
}


import { ADD_FAQS_PAGE, GET_FAQS_LIST, ADD_DYNAMIC_PAGE, GET_DYNAMIC_PAGE } from "../types";
import daynamicpage from "../../api/daynamicpage";

export const saveFaqsPages = (payload) => (dispatch) => {
  return daynamicpage.savePages(payload).then(
    (data) => {
      dispatch({
        type: ADD_FAQS_PAGE,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const getFaqsPages = (params) => (dispatch) => {
  return daynamicpage.getFaqs(params).then(
    (response) => {
      dispatch({
        type: GET_FAQS_LIST,
        payload: {
          data: response?.data,
          hasNextPage : response?.hasNexPage,
          totalCount : response?.totalCount,
          currentPage : response?.currentPage,
          totalpageCount : response?.totalpageCount
        },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const saveDynamicPage = (payload) => (dispatch) => {
  return daynamicpage.saveDynamicPage(payload).then(
    (data) => {
      dispatch({
        type: ADD_DYNAMIC_PAGE,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


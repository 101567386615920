import React, { useState, useEffect, Fragment } from "react";
import style from "./buyerLead.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
// import LeadTable from "./components/table/BuyerTable";
import { FaArrowLeft ,} from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { buyerApi } from "../../api/buyerLeadApi";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../components/buttons/multiSelect";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import { CiCircleRemove, CiSearch } from "react-icons/ci";
import LeadTable from "./components/table/LeadTable";
import {
  Box,
  Checkbox,
  IconButton,
  Modal,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { CheckBox } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const styleModel = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function BuyerLead() {
  const LeadAssigned = [
    { id: 1, statusName: "All" },
    { id: 2, statusName: "Assigned" },
    { id: 3, statusName: "Unassigned" },
  ];

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, settotalPageCount] = useState();
  const [totalCount, settotalCount] = useState();
  const [usersRoleName, setUsersRoleName] = useState();
  const navigate = useNavigate();
  const [hasNextPage, sethasNextPage] = useState();
  const [buyerLeadStatus, setbuyerLeadStatus] = useState([]);
  const [userList, setUserList] = useState([]);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [roleIds, setRoleId] = useState(null);


  const [filter, setFilter] = useState({
    searchString: "",
    leadRating: "",
    leadStatus: "",
    startDate: "",
    endDate: "",
    salesPoc: "",
    talecaller: "",
    user: "",
  });
  const [assignFilter, setAssignFilter] = useState("all");
  const [assignedModel, setAssignedModel] = useState(false);
  const [assignedToBtn, setAssignedToBtn] = useState(true);
  const [assignedToData, setAssignedToData] = useState([]);
  const [checkbox, setCheckbox] = useState({});
  const [isAssignedTo, setIsAssignedTo] = useState("");
  const [leadIdAssignedTo, setLeadIdAssignedTo] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  const handleCheckboxChange = (rowId, value) => {
    setCheckbox((prev) => ({ ...prev, [rowId]: value }));
  };

  const handleAllCheckbox = (value) => {
    const checkboxs = {};
    assignedToData.forEach((row) => {
      checkboxs[row.id] = value;
    });
    setCheckbox(checkboxs);
  };

  const isAllSelected =
    assignedToData.length > 0 &&
    assignedToData.every((row) => checkbox[row.id]);
  const isSomeSelected =
    assignedToData.some((row) => checkbox[row.id]) && !isAllSelected;

  const columns = [
    {
      accessorKey: "check",
      header: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={isSomeSelected}
          onChange={(e) => handleAllCheckbox(e.target.checked)}
          inputProps={{ "aria-label": "Select all rows" }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={!!checkbox[row?.original?.id]}
          onChange={(e) => {
            handleCheckboxChange(row.original.id, e.target.checked);
          }}
          inputProps={{ "aria-label": `Checkbox for row ${row.id}` }}
        />
      ),
    },
    { accessorKey: "firstName", header: "Name" },
    { accessorKey: "phone", header: "Mobile" },
  ];


  const customer = async (params = { pageNo: 1 }) => {
 
    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== "")
    );

    if (cleanedParams.startDate || cleanedParams.endDate) {
      const dateKey = cleanedParams.leadStatus == 2 ? "follow" : "created";
      cleanedParams.dateKey = dateKey;
    }

    const queryParams = { pageSize: 10, ...cleanedParams };

    try {
      const res = await buyerApi.getCustomerInfo(queryParams);
      if (res) {
        setCustomerList(res.data);
        settotalCount(res.totalRecords);
        sethasNextPage(res.hasNext);
        settotalPageCount(Math.ceil(res?.totalRecords / 10));
      }
    } catch (err) {
      console.error("Failed to fetch customer info:", err);
    }
  };


  const handleRatingChange = (e) => {
    const lowercaseRating = e.value.toLowerCase();
    setFilter({ ...filter, leadRating: lowercaseRating });
  };

  const handleAssignedToSelect = async () => {
    try {
      const res = await buyerApi.getDealerSubUser();
      if (res?.status) {

        const userList   = res?.data.filter(user => user.parentRoleId !==  57)
        console.log("userList ", userList);
        setUserList(userList);
      
      }
    } catch (error) {
      console.error("Error in get-dealers-sub-user:", error);
    }
  };

  useEffect(() => {
    handleAssignedToSelect();
  }, []);

  useEffect(() => {
      const storedRoleId = localStorage.getItem('roleId');
      setRoleId(storedRoleId);
    }, []);


    const isDropdownDisabled = roleIds !== '57' && roleIds !== '63';

  const ratingOptions = [
    { label: "Hot", value: "hot" },
    { label: "Cold", value: "cold" },
    { label: "Warm", value: "warm" },
  ];

  const buyerLeadStstus = async () => {
    try {
      const res = await buyerApi.getBuyerLeadStatus();
      if (res) {
        setbuyerLeadStatus(res.data);
      }
    } catch (err) {
      console.error("Failed to fetch customer info:", err);
    }
  };

  const statusOptions = [
    { label: "New", value: 1 },
    { label: "Follow-Up", value: 2 },
    { label: "Done", value: 10 },
  ];

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleStartDateChange = (date, name) => {
    setFilter({
      ...filter,
      [name]: formatDate(date),
    });
  };

  const handleEndDateChange = (date, name) => {
    setFilter({
      ...filter,
      [name]: formatDate(date),
    });
  };

  useEffect(() => {
    customer();
  },[]);

  useEffect(() => {
    buyerLeadStstus();
  }, []);

  const handleFilter = (str) => {
    setAssignFilter(str);
  };

  
  const handleAssignedToBtn = async (btnName) => {
    const role = btnName == "telecaller" ? 65 : 64;

    setIsAssignedTo(btnName);
    try {
      const res = await buyerApi.getDealerSubUser({ roleId: role });
  
      if (res?.status) {
        setAssignedToData(res?.data);
        setAssignedModel(true);
        setUsersRoleName(res?.data[0].roleName);
      }
    } catch (error) {
      console.error("Error in get-dealers-sub-user:", error);
    }
  };

  const table = useReactTable({
    data: assignedToData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleAssignedTo = async (btnName) => {
    const checkedKeys = Object.keys(checkbox).filter((key) => checkbox[key]);
    const params = {
      userId: checkedKeys,
      assign_to: btnName,
      leads: leadIdAssignedTo,
    };
    try {
      const result = await buyerApi.assignUser(params);
      if (result.status) {
        toast.success(result.message);
        setAssignedModel(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    customer({ ...filter, pageNo: page });
  };
  const isBulkLeadUpload = localStorage.getItem("is_bulk_lead_upload");

  return (
    <Fragment>
      <div className={style["container"]}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 600, px: 2 }}
          >
            Buyer Lead - Customer Management {`(${totalCount} Leads)`}
          </Typography>

          {isBulkLeadUpload === "1" && (
            <Box>
              <button
                onClick={() => {
                  navigate("/lead-managment");
                }}
                className={style["bulk-upload-route-button"]}
              >
                <MdCloudUpload />
                Bulk Upload
              </button>
            </Box>
          )}
        </Box>

        <div className={style["filters-container"]}>
          <div className={style["filters-wrapper"]}>
            <div className={style["search-cont"]}>
              <SearchBar
                placeholder={"Name / Mobile"}
                value={filter?.searchString}
                handleChange={(e) =>
                  setFilter({ ...filter, searchString: e.target.value })
                }
                name="mobile"
                className={style["search-mobile"]}
              />
            </div>
            <MultiSelect
              name="rating"
              handleChange={handleRatingChange}
              items={ratingOptions}
              selectedItems={filter?.leadRating}
              hint={"Lead Rating"}
              isSearchable={true}
              containerStyle={style["selectable-button-container-style"]}
              selectClass={style["filterDropDown"]}
            />

            <MultiSelect
              name="status"
              handleChange={(e) =>
                setFilter({ ...filter, leadStatus: e.value })
              }
              items={buyerLeadStatus.map((status) => ({
                label: status.statusName,
                value: status.id,
              }))}
              selectedItems={filter?.leadStatus}
              hint={"Lead Status"}
              isSearchable={true}
              containerStyle={style["selectable-button-container-style"]}
              selectClass={style["filterDropDown"]}
            />

            <div
              style={{
                display: "inline-block",
              }}
            >
              <DatePicker
                className={style["date-change"]}
                selected={filter.startDate}
                onChange={(date) => handleStartDateChange(date, "startDate")}
                dateFormat="DD-MM-yyyy"
                placeholderText="Start Date"
                customInput={
                  <div className={style.customDateInputBox}>
                    <span className={style.customDateText}>
                      {filter.startDate
                        ? moment(filter.startDate).format("DD-MM-yyyy")
                        : "Start Date"}
                    </span>
                    <FaCalendarAlt
                      style={{ marginLeft: "75px" }}
                      className={style.calendarIcon}
                    />
                  </div>
                }
              />
            </div>

            <div style={{ display: "inline-block" }}>
              <DatePicker
                className={style["date-change"]}
                selected={filter.endDate}
                onChange={(date) => handleEndDateChange(date, "endDate")}
                dateFormat="DD-MM-yyyy"
                placeholderText="End Date"
                customInput={
                  <div className={style.customDateInputBox}>
                    <span className={style.customDateText}>
                      {filter.endDate
                        ? moment(filter.endDate).format("DD-MM-yyyy")
                        : "End Date"}
                    </span>
                    <FaCalendarAlt
                      style={{ marginLeft: "80px" }}
                      className={style.calendarIcon}
                    />
                  </div>
                }
              />
            </div>

            <PrimaryButton
              containerStyle={style["add-new-car-button-container-style"]}
              name={" Filter"}
              fontSize={"1rem"}
              onClick={() => customer(filter)}
            />

            <PrimaryButton
              containerStyle={style["add-new-car-button-container-style"]}
              name={"Reset"}
              fontSize={"1rem"}
              onClick={() => {
                setFilter({
                  searchString: "",
                  leadRating: "",
                  leadStatus: "",
                  startDate: "",
                  endDate: "",
                  salesPoc: "",
                  talecaller: "",
                });
                setCurrentPage(1)
                customer();
              }}
            />

            <div className={style["advance-filter-button-container"]}>
              <a
                href="#"
                style={{
                  fontSize: "0.8rem",
                  textDecoration: "none",
                  color: isDropdownDisabled ? "gray" : "blue", // Gray for disabled state
                  cursor: isDropdownDisabled ? "not-allowed" : "pointer", // Not-allowed cursor when disabled
                }}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  if (!isDropdownDisabled) {
                    setAdvanceFilter(!advanceFilter); // Toggle only if not disabled
                  }
                }}
              >
                Advance Filter
              </a>
            </div>
          </div>

          <div></div>

          {advanceFilter && (
            <div style={{ marginTop: "10px" }}>
              <div className={style["filters-wrapper"]}>
                {!isDropdownDisabled && (
                  <MultiSelect
                    name="status"
                    handleChange={(e) =>
                      setFilter({ ...filter, talecaller: e.value })
                    }
                    items={LeadAssigned.map((status) => ({
                      label: status.statusName,
                      value: status.id,
                    }))}
                    selectedItems={filter?.talecaller}
                    hint={"Talecaller"}
                    isSearchable={true}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                  />
                )}

                {!isDropdownDisabled && (
                  <MultiSelect
                    name="status"
                    handleChange={(e) =>
                      setFilter({ ...filter, salesPoc: e.value })
                    }
                    items={LeadAssigned.map((status) => ({
                      label: status.statusName,
                      value: status.id,
                    }))}
                    selectedItems={filter?.salesPoc}
                    hint={"Sales Poc"}
                    isSearchable={true}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                  />
                )}
                {!isDropdownDisabled && (
                  <MultiSelect
                    style={{ width: "500px" }}
                    name="status"
                    handleChange={(e) =>
                      setFilter({ ...filter, user: e.value })
                    }
                    items={userList.map((status) => ({
                      label: (
                        <span>
                          {status.firstName}
                          <span className={style["roleName"]}>
                            -{status.roleName}
                          </span>
                        </span>
                      ),
                      value: status.id,
                    }))}
                    selectedItems={filter?.user}
                    hint={"Users"}
                    isSearchable={true}
                    containerStyle={style["selectable-button-container-st"]}
                    selectClass={style["filterDropDown"]}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <div className={style["filters-container-second"]}>
          <div className={style["filters-wrapper-between"]}>
            <div className={style["filters-wrapper"]}></div>
            <div
              style={{ marginTop: "10px" }}
              className={style["filters-wrapper"]}
            >
              <button
                disabled={leadIdAssignedTo.length === 0}
                className={`${
                  leadIdAssignedTo.length === 0
                    ? style["search-action-wrapper-disabled"]
                    : style["search-action-wrapper"]
                }`}
                style={{
                  cursor: `${assignedToBtn ? "not-allowed" : "pointer"}`,
                }}
                onClick={() => handleAssignedToBtn("telecaller")}
              >
                Assign Talecaller
              </button>
              <button
                disabled={leadIdAssignedTo.length === 0}
                className={`${
                  leadIdAssignedTo.length === 0
                    ? style["search-action-wrapper-disabled"]
                    : style["search-action-wrapper"]
                }`}
                style={{
                  cursor: `${assignedToBtn ? "not-allowed" : "pointer"}`,
                }}
                onClick={() => handleAssignedToBtn("poc")}
              >
                Assign Sales POC
              </button>
            </div>
          </div>
        </div>

        <LeadTable
          data={customerList}
          customer={customer}
          assignFilter={assignFilter}
          setLeadIdAssignedTo={setLeadIdAssignedTo}
          setAssignedToBtn={setAssignedToBtn}
        />

        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={"20px"}
        >
          <Pagination
            count={totalPageCount}
            page={currentPage}
            onChange={(event, page) => handlePageChange(page)}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
      <Modal
        open={assignedModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box  sx={{
          ...styleModel,
         marginTop: "100px", 
          }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {usersRoleName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setAssignedModel(false)}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>

          <div
            className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
            style={{
              maxHeight: assignedToData.length > 10 ? "400px" : "auto", // Enable scroll for more than 10 users
              overflowY: assignedToData.length > 10 ? "auto" : "visible", // Add vertical scrolling
              border: "1px solid #ddd", 
            }}
          >
            <table
              className={`${style["table-wrapper"]} table table-border table-hover`}
              cellSpacing="5px"
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup?.id}
                    className={style["table-header-wrapper"]}
                  >
                    {headerGroup?.headers?.map((header) => (
                      <th
                        key={header?.id}
                        className={style["table-header"]}
                        style={{ paddingBottom: "30px !important" }}
                      >
                        {flexRender(
                          header?.column?.columnDef?.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {assignedToData.length > 0 ? (
                  table?.getRowModel()?.rows?.map((row) => (
                    <tr key={row?.id} className={style["table-row"]}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell?.column?.columnDef?.cell,
                            cell?.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className={style["no-data-row"]}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <button
            className={style["search-action-wrapper"]}
            onClick={() => handleAssignedTo(isAssignedTo)}
          >
            Submit
          </button>
        </Box>


      </Modal>
    </Fragment>
  );
}

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/SidebarNew";
import Dashboard from "./screens/dashboard/Dashboard";
import CarManagement from "./screens/car-management/CarManagement";
import BuyerLead from "./screens/buyer-lead/BuyerLead";
import InquiryManagement from "./screens/inquiry-management/InquiryManagment";
import LeadList from "./screens/lead-managment/LeadManagement";
import SellerLead from "./screens/seller-lead/SellerLead";
import AddNewCar from "./screens/add-new-car/AddNewCar";
import EditCarDetails from "./screens/edit-car-details/EditCarDetails";
import Signin from "./screens/login/Signin";
import PrivateRoutes from "./utils/PrivateRoutes";
import DealerBlogs from "./screens/dealers-blogs/DealerBlogs";
import BuyerEditLeads from "./screens/buyer-lead/components/buyer-edit-leads/BuyerEditLeads";
import InquireEditLeads from "./screens/inquiry-management/components/enquire-edit-leads/InquireEditLeads";
import store from "./store/store";
import { Provider } from "react-redux";
import SellerEditLeads from "./screens/seller-lead/components/seller-edit-leads/SellerEditLeads";
import CreateBlogs from "./screens/dealers-blogs/create-blogs";
import Testimonials from "./screens/testimonials/Testimonials";
import CreateTestimonials from "./screens/testimonials/create-testimonial/Create-testimonial";
import UpdateCarImages from "./screens/edit-car-details/UpdateCarImages";
import Preference from "./screens/customer-preferences";
import { FaqsTable } from "./screens/faqs-management";
import { CreateFaqs } from "./screens/faqs-management/createFaqs";
import { DaynamicPages } from "./screens/daynamic-pages";
import { CreateDaynamicPages } from "./screens/daynamic-pages/createpage";

function App() {
  const loginCheck = () => {
    return localStorage.getItem("accessToken") ? true : false;
  };
  return (
    <div className={"custom-container"}>
      <Provider store={store}>
        <BrowserRouter>
          {loginCheck() ? (
            <div>
              <div className={"wrapper"}>
                <Sidebar />
                <div className={"routes-wrapper"}>
                  <Routes>
                    <Route element={<PrivateRoutes />}>
                      <Route
                        path="/"
                        element={<Navigate to={"/car-management"} />}
                      />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route
                        path="/car-management"
                        element={<CarManagement />}
                      />
                      <Route
                        path="/lead/customer-preferences/:path"
                        element={<Preference />}
                      />

                      <Route
                        path="/buyer-lead-management"
                        element={<BuyerLead />}
                      />
                      <Route
                        path="/upload-images"
                        element={<UpdateCarImages />}
                      />
                      <Route
                        path="/inquiry-management"
                        element={<InquiryManagement />}
                      />
                      {localStorage.getItem("is_bulk_lead_upload") == "1" && (
                        <Route path="/lead-managment" element={<LeadList />} />
                      )}
                      <Route
                        path="/edit-buyer-lead"
                        element={<BuyerEditLeads />}
                      />
                      <Route
                        path="/edit-inquire-lead"
                        element={<InquireEditLeads />}
                      />

                      <Route
                        path="/seller-lead-management"
                        element={<SellerLead />}
                      />

                      <Route
                        path="/edit-seller-lead"
                        element={<SellerEditLeads />}
                      />
                      <Route path="/add-new-car" element={<AddNewCar />} />
                      <Route
                        path="/edit-car-details"
                        element={<EditCarDetails />}
                      />
                      <Route path="/dealers-blogs" element={<DealerBlogs />} />

                      <Route path="/create-blogs" element={<CreateBlogs />} />

                      <Route path="/testimonials" element={<Testimonials />} />
                      <Route
                        path="/create-testinomial"
                        element={<CreateTestimonials />}
                      />
                      <Route path="/faqs-listing" element={<FaqsTable />} />
                      <Route path="/create-faqs" element={<CreateFaqs />} />
                      <Route path="/daynamic-page-list" element={<DaynamicPages />} />
                      <Route
                        path="/create-daynamic-page"
                        element={<CreateDaynamicPages />}
                      />

                    </Route>
                  </Routes>
                </div>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Navigate to={"/login"} />} />
              <Route element={<Signin />} path="/login" />
              <Route path="*" element={<Navigate to={"/login"} />} />
            </Routes>
          )}
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;

import {
  call,
  carCall,
  dealerSaveInventory,
  carCallImage,
  dealerAppAuth,
  leadCall,
  addCarReccomended,
} from ".";
import { dealerId } from "../constants/resources";

class CarApi {
  editCarDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/save",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  uploadCarImages(data) {
    const token = localStorage.getItem("accessToken");

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCallImage(
            "post",
            "dealerInventory/inventory/upload-car-image",
            null,
            data,
            token
          );
          console.log();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  saveUploadCarImages(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/save-car-image",
            null,
            data,
            token
          );
          console.log();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  removeStockImage(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/remove-car-image",
            null,
            data,
            token
          );
          console.log();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  postCarDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSaveInventory(
            "post",
            "dealerInventory/inventory/save",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  savePnlData(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSaveInventory(
            "post",
            "dealerInventory/inventory/savePnl",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDealerPnL(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSaveInventory(
            "post",
            "dealerInventory/inventory/get-inventory-pnl",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getCarList(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/search",
            null,
            data,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getLeadCarList(data) {
    console.log("nnnnnnnnnnnnnnnnnn ", data.leadId);
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await leadCall(
            "get",
            `dealer-app/buyer-lead/get-lead-inventory?leadId=${data.leadId}`,
            null,

            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getVehicleInfo(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "get",
            "dealer/getDealerVehicleInfo?car_id=" + data,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  imgTypeEnumList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "get",
            "dealerInventory/inventory/get-image-tags",
            params,
            null,
            token
          );
          resolve(res.data?.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  dealerAddress(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerAppAuth(
            "get",
            "dealer-app/get-dealer-address",
            params,
            null,
            token
          );
          resolve(res?.data);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  saveSoldDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSaveInventory(
            "post",
            "dealerInventory/inventory/savePnl",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  cancelBooking(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSaveInventory(
            "post",
            "dealerInventory/inventory/cancel-booking",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addRecommended(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await addCarReccomended(
            "post",
            "dealer-app/buyer-lead/lead-inventory-preferences",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  dealerUsers(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerAppAuth(
            "get",
            "dealer-app/get-dealer-user",
            params,
            null,
            token
          );
          resolve(res?.data);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  allDealers(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerAppAuth(
            "get",
            "dealer-app/get-dealers-d2d-list",
            params,
            null,
            token
          );
          resolve(res?.data);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  source(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerAppAuth(
            "get",
            "dealer-app/get-dealer-source",
            params,
            null,
            token
          );
          resolve(res?.data);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  purchesar(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerAppAuth(
            "get",
            "dealer-app/get-dealer-purchaser",
            params,
            null,
            token
          );
          resolve(res?.data);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  stateList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "get",
            "inventoryService/public/get-state",
            null,
            null,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  cityList(param) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "get",
            `inventoryService/public/get-city?state=${param}`,
            null,
            null,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const carApi = new CarApi();

import React, { Fragment, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import style from "./detailTable.module.scss";
import { useNavigate } from "react-router-dom";
import { R } from "../../constants/resources";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Box,
  CardActionArea,
  CardMedia,
  Checkbox,
  Chip,
  Fab,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Switch,
} from "@mui/material";
import { carApi } from "../../api/carApi";
import { GiGearStick } from "react-icons/gi";
// import EditIcon from "@mui/icons-material/Edit";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import NavigationIcon from "@mui/icons-material/Navigation";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, MenuItem } from "@mui/material";
import CarRentalIcon from "@mui/icons-material/CarRental";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import MultiSelect from "../buttons/multiSelect";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SavePnLDetails, GetPnlData } from "../../store/car/carAction";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { formatIndianCurrency } from "../../utils/PrivateRoutes.js";
import CarManagementCard from "../cards/CarManagmentCard";
import { SoldManagement } from "../../screens/add-new-car/components/soldManagement/index.jsx";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Swiper core styles
import "swiper/css/navigation"; // Optional: If using navigation buttons
import "swiper/css/pagination"; // Optional: If using pagination
import { Navigation, Pagination } from "swiper/modules";

import {
  fetchDealerAddress,
  // fetchPurchesar,
  // fetchSource,
  // fetchAllDealers,
  // fetchDealerUsers,
} from "../../store/car/carAction.js";
import { useDispatch, useSelector } from "react-redux";
import { getBuyerList } from "../../store/buyerLead/buyerLeadAction.js";
import { CarCrash } from "@mui/icons-material";
// import { showToast } from "../toaster/Toaster.js";
import { showToast } from "../../components/toaster/toaster.js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DetailTable({ leadCarList, setLeadCarList }) {
  const [carIds, setcarIds] = useState("");
  const { dealerAddress, dealerUsers, carDealers, purchaser, source } =
    useSelector((state) => state?.car || []);
  const buyerLeadList = useSelector(
    (state) => state?.buyerLead?.buyerLeadList || []
  );
  const isPnl = localStorage.getItem("isPnl");

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchDealerAddress());
    // dispatch(fetchPurchesar());
    // dispatch(fetchAllDealers());
    // dispatch(fetchSource());
    // dispatch(fetchDealerUsers());
    dispatch(getBuyerList({ leadStatus: `[${[1, 10].join(",")}]` }));
  }, [dispatch]);

  const formatDate = (dateString) => {
    return moment(dateString).format("YYYY-MM-DD");
  };

  const [formData, setFormData] = useState({
    purchase_date: "",
    purchase_amount: "",
    refurbisment_cost: "",
    others_cost: "",
    sold_date: "",
    sold_amount: "",
    sold_to: "",
  });

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    // Handle specific fields that require numeric values
    if (
      name === "purchase_amount" ||
      name === "refurbisment_cost" ||
      name === "others_cost" ||
      name === "sold_amount"
    ) {
      const rawValue = value.replace(/\D/g, "");
      setFormData({ ...formData, [name]: rawValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [loading, setLoading] = useState({});
  const [open, setOpen] = useState(false);
  const [handleBackDrop, setHandleBackDrop] = useState(false);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState({});
  const [dialogBox, setDialogBox] = useState({
    id: "",
    titleMessage: "",
    confirmMessage: "",
    showField: "",
    handleConfirm: () => {},
  });
  const [view, setView] = useState("grid");

  const [openPnL, setPnL] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [soldValue, setSoldValue] = useState("");
  const [openSold, setOpenSold] = useState(false);
  const salesInfo = localStorage.getItem("is_capture_sale_info");
  const [soldCarId, setSoldCarId] = useState("");
  const [sqlId, setSqlId] = useState("");
  const [leadOpenPnl, setLeadOpenPnl] = useState("");
  const [purchaseSeen, setPurchaseSeen] = useState("");
  const [soldenValue, setSoldenValue] = useState("");
  const [purchaseValue, setPurchaseValue] = useState(0);
  const [refValue, setRefValu] = useState(0);
  const [otherValue, setOtherValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [soldAmountValue, setSoldAmountValue] = useState(0);
  const [bookedValue, setBookedValue] = useState("");

  const handleViewChange = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const handleSoldClose = () => {
    setOpenSold(false);
    setSoldValue("");
    setSoldCarId("");
    setLeadOpenPnl("");
    setPurchaseSeen("");
    setSoldAmountValue(0);
    setProfit(0);
    setOtherValue(0);
    setRefValu(0);
    setPurchaseValue(0);
    setSoldenValue("");
  };

  useEffect(() => {
    if (
      ((soldValue === "3" || soldValue === "2" || soldValue === "3") &&
        salesInfo === "1") ||
      isPnl === "1"
    ) {
      setOpenSold(true);
    }
  }, [soldValue, salesInfo, isPnl, soldenValue]);

  const columns = [
    {
      accessorKey: "imagesDetails",
      header: "",
      enableColumnFilter: false,
    },
    { accessorKey: "carSkuId", header: "Sku Id" },
    { accessorKey: "make", header: "Make" },
    { accessorKey: "modalName", header: "Model" },
    { accessorKey: "varientName", header: "Variant" },
    { accessorKey: "makeYear", header: "Year" },
    { accessorKey: "carPrice", header: "Price" },
    { accessorKey: "reg_no", header: "Vehicle No." },
    { accessorKey: "status", header: "Status" },
    {
      accessorKey: "action",
      header: "Action",
      enableColumnFilter: false,
    },
    { accessorKey: "IsClassified", header: "Is Classified" },
  ];

  const handleTooltipClose = (carSkuId) => {
    setCopyTooltipOpen((prev) => ({ ...prev, [carSkuId]: false }));
  };

  const handleTooltipOpen = (carSkuId) => {
    navigator.clipboard
      .writeText(carSkuId)
      .then(() => {
        setCopyTooltipOpen((prev) => ({
          ...prev,
          [carSkuId]: true,
        }));
        setTimeout(() => {
          setCopyTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleClickOpen = (data) => {
    const carSkuId = data?.data?.carSkuId;
    setDialogBox({
      id: carSkuId,
      titleMessage: "! Warning",
      confirmMessage: "Are you sure you want to mark this vehicle as Inactive?",
      handleConfirm: () => handleDelete(carSkuId),
    });
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleWarningMessage = (e, data, type) => {
    const isChecked = e.target.checked;
    const action = isChecked
      ? "list this vehicle at"
      : "remove this vehicle from";
    const location =
      type === "is_website" ? "the website" : "MotorPedia Website ";

    e.preventDefault();

    const confirmMessage = `Are you sure you to ${action} ${location}?`;

    setDialogBox({
      titleMessage: "! Warning",
      confirmMessage: confirmMessage,
      handleConfirm: () => {
        handleSwitchBtn(isChecked, data, type);
      },
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectStatus = (value, carSkuId) => {
    if ((value === "3" || value === "2") && salesInfo === "1") {
      console.log("Dialog not opened because value is 3 and salesInfo is 1");
      return;
    }

    setDialogBox({
      id: carSkuId,
      titleMessage: "! Warning",
      confirmMessage: "Do you want to update the status!",
      handleConfirm: () => handleConfirmChange(value, carSkuId),
    });
    setOpen(true);

    if (!value) {
      setLeadCarList((prevData) =>
        prevData.map((car) =>
          car.carSkuId === carSkuId ? { ...car, car_status: "" } : car
        )
      );
    }
  };

  const table = useReactTable({
    leadCarList,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const navigate = useNavigate();
  const onEditClick = (data, link) => {
    if (link === "upload-images") {
      navigate(`/${link}`, { state: data?.carSkuId });
    } else {
      navigate(`/${link}`, { state: data });
    }
  };

  const handleSwitchBtn = async (isChecked, data, name) => {
    const carSkuId = data?.data?.carSkuId;
    const value = isChecked ? "1" : "0";
    setLoading((prev) => ({ ...prev, [carSkuId]: { [name]: true } }));

    setLeadCarList((prevData) =>
      prevData.map((car) =>
        car.carSkuId === carSkuId ? { ...car, [name]: value } : car
      )
    );

    if (name === "is_classified") name = "isClassified";
    if (name === "is_website") name = "isWebsite";

    try {
      await carApi.editCarDetails({
        carSkuId,

        [name]: value,
        is_inventory: "update",
      });
      toast.success("successfull update");
      handleClose();
    } catch (error) {
      handleClose();
      console.error("Error updating car details", error);
    } finally {
      setLoading((prev) => ({ ...prev, [carSkuId]: { [name]: false } }));
    }
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleDelete = async (carSkuId) => {
    setHandleBackDrop(true);
    try {
      await carApi.editCarDetails({
        carSkuId,
        carStatus: "0",
        is_inventory: "update",
      });
    } catch (error) {
      console.error("Error deleting car", error);
    } finally {
      setLeadCarList((prevData) =>
        prevData.map((car) =>
          car.carSkuId === carSkuId ? { ...car, car_status: "0" } : car
        )
      );
      handleClose();
      setHandleBackDrop(false);
    }
  };

  const handleConfirmChange = async (value, carSkuId) => {
    if (value && carSkuId) {
      setHandleBackDrop(true);
      handleClose();
      try {
        await carApi.editCarDetails({
          carSkuId,
          carStatus: value,
          is_inventory: "update",
        });
      } catch (error) {
        console.error("Error deleting car", error);
      } finally {
        setLeadCarList((prevData) =>
          prevData.map((car) =>
            car.carSkuId === carSkuId ? { ...car, car_status: value } : car
          )
        );
        setHandleBackDrop(false);
      }
    }
  };

  useEffect(() => {
    let totalProfit = soldAmountValue - purchaseValue + refValue + otherValue;
    setProfit(totalProfit);
  }, [refValue, purchaseValue, otherValue, soldAmountValue]);

  const Action = (data) => {
    const carSkuId = data?.data?.carSkuId;
    const isDeleteLoading = loading[carSkuId]?.delete;

    return (
      <Stack
        direction="row"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
        spacing={1}
        justifyContent="flex-end"
      >
        <Tooltip title="Edit">
          <IconButton
            size="small"
            onClick={() => onEditClick(data, "edit-car-details")}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        {isDeleteLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Tooltip title="Delete">
            <IconButton size="small" onClick={() => handleClickOpen(data)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  };

  const ActionList = (data) => {
    const carSkuId = data?.data?.carSkuId;
    //  const isClassifiedLoading = loading[carSkuId]?.is_classified;
    const isWebsiteLoading = loading[carSkuId]?.is_website;
    const isAssuredLoading = loading[carSkuId]?.isAssured;
    const isClassifiedItem = localStorage.getItem("is_classifieds");
    const isWebsiteItem = localStorage.getItem("is_website");
    // console.log("isClassifiedItem", isWebsiteItem);

    return (
      <>
        {isWebsiteItem == 1 && (
          <div
            className={style["action-container"]}
            style={{
              display: window.innerWidth <= 768 ? "flex" : "grid",
              padding: "3px",
            }}
          >
            <div>
              {isWebsiteLoading ? (
                <div className={style["text-center"]}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "flex-start",
                    // border: "1px solid black",
                    // padding: "10px",
                  }}
                >
                  <div>
                    <input
                      title="is_website"
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={data?.data?.is_website === "1"}
                      onChange={(e) =>
                        handleWarningMessage(e, data, "is_website")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span style={{ paddingLeft: "10px" }}>
                      {data?.data?.is_website === "1"
                        ? "List on Website"
                        : "List on Website"}
                    </span>
                  </div>

                  <div>
                    <input
                      title="is_classified"
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={data?.data?.is_classified === "1"}
                      onChange={(e) =>
                        handleWarningMessage(e, data, "is_classified")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span style={{ paddingLeft: "10px" }}>
                      {data?.data?.is_classified === "1"
                        ? "List on MotorPedia"
                        : "List on MotorPedia"}
                    </span>
                  </div>
                </div>
              )}

              {isAssuredLoading ? (
                <div className={style["text-center"]}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div style={{ display: "none", alignItems: "center" }}>
                  <input
                    title="isAssured"
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={data?.data?.isAssured}
                    onChange={(e) => handleSwitchBtn(e, data, "isAssured")}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    {data?.data?.isAssured ? "Assured" : "Assured"}
                  </span>
                </div>
              )}
            </div>

            <div className={style["urlContainer"]}>
              {data?.data?.is_website === "1" ||
              data?.data?.is_classified === "1" ? (
                <div className={style["box"]}>
                  <div className={style["headingView"]}>View on</div>
                  <div className={style["links"]}>
                    {data?.data?.is_website === "1" && (
                      <span
                        title="My Website"
                        className={style["w-logo"]}
                        onClick={() =>
                          window.open(data.data.dealer_vdp_link, "_blank")
                        }
                      >
                        W
                      </span>
                    )}
                    {data?.data?.is_classified === "1" &&
                      data?.data?.classifieds_vdp_link && (
                        <img
                          title="View on MotorPedia"
                          className={style["mp-logo"]}
                          src={R.img_mp_fav}
                          alt="ic_edit"
                          onClick={() =>
                            window.open(
                              data.data.classifieds_vdp_link,
                              "_blank"
                            )
                          }
                        />
                      )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </>
    );
  };

  const CarImg = (data) => (
    <Fragment>
      <div className={style["car-image-box"]} style={{ position: "relative" }}>
        <img
          className={style["car-image"]}
          src={data?.data?.imagesDetails?.[0]?.imgUrls || R.img_nocarfound}
          alt="ic_edit"
          onClick={() => onEditClick(data, "upload-images")}
          style={{ position: "relative", top: 0, left: 0, right: 0, bottom: 0 }}
        />
        <p
          className={style["view-image-text"]}
          style={{
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "5px",
            borderRadius: "5px",
            color: "#fff",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          View
        </p>
      </div>
      <div
        className={style["add-icon-parent"]}
        onClick={() => onEditClick(data, "upload-images")}
      >
        {/* <img
          title="Upload Image"
          className={style["add-icons"]}
          src={R.ic_add}
          alt="ic_edit"
        /> */}
      </div>
    </Fragment>
  );

  const Status = React.memo((data) => {
    const carSkuId = data?.data?.carSkuId;
    const currentStatus = data?.data?.car_status;
    const items = [
      { label: "Active", value: "1" },
      { label: "Inactive", value: "0" },
      { label: "Booked", value: "2" },
      { label: "Sold", value: "3" },
    ];

    const selectedStatus = items.filter((item) => item.value === currentStatus);

    return (
      <Fragment>
        <div
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <MultiSelect
            name="status"
            handleChange={(e) => {
              setSoldValue(e.value);
              handleSelectStatus(e?.value, carSkuId);
              setSoldCarId(data?.data?.car_id);
              setSqlId(data?.data?.carSkuId);
            }}
            items={items}
            showCrossIcon={false}
            minWidth="150px"
            selectedItems={selectedStatus}
            hint={"All Status"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
        </div>
      </Fragment>
    );
  });

  const SkuId = ({ leadCarList }) => {
    const carSkuId = leadCarList?.carSkuId;
    const splitCarSkuId = carSkuId.split("-")[0];
    return (
      <div className={style["flex-between"]}>
        <p>{splitCarSkuId}...</p>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={copyTooltipOpen[carSkuId] || false}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
          >
            <IconButton
              onClick={() => handleTooltipOpen(carSkuId)}
              sx={{ mt: -1 }}
            >
              <ContentCopySharpIcon fontSize="small" titleAccess={carSkuId} />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </div>
    );
  };

  const handleDeleteBooked = async (reason, leadCarList) => {
    const payload = {
      car_id: leadCarList?.car_id,
      action: "booking-cancel",
      comment: reason || "",
    };
    try {
      await carApi.cancelBooking(payload);
      setLeadCarList((prevData) =>
        prevData.map((car) =>
          car.carSkuId === leadCarList?.carSkuId
            ? { ...car, car_status: "1" }
            : car
        )
      );
      handleClose(true);
      showToast("sucess", "Booking Cancel");
    } catch (error) {
      showToast("error", "Booking Error");
    }
  };

  const handleStatus = async (car) => {
    const data = car;
    setDialogBox({
      id: data?.carSkuId,
      titleMessage: "! Warning",
      showField: "1",
      confirmMessage: "Are you sure you want to cancel Booking?",
      handleConfirm: (reason) => handleDeleteBooked(reason, data),
    });
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Lead Intrested Cars</Typography>
        <IconButton
          aria-label="toggle view"
          onClick={handleViewChange}
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          {view === "list" ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>
      <div>
        <>
          {view === "grid" ? (
            <div style={{ position: "relative" }}>
              <Swiper
                className="container"
                spaceBetween={10}
                slidesPerView={4}
                navigation={{
                  nextEl: ".custom-swiper-button-next",
                  prevEl: ".custom-swiper-button-prev",
                }}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1280: {
                    slidesPerView: 4,
                  },
                }}
              >
                {leadCarList?.map((car, index) => (
                  <SwiperSlide key={index}>
                    <Card sx={{ margin: 1 }}>
                      <Box sx={{ position: "relative" }}>
                        {car?.sub_source_name === "Recommended" && (
                          <Chip
                            label={car?.sub_source_name}
                            sx={{
                              backgroundColor: "#fff",
                              position: "absolute",
                              top: 10,
                              right: 10,
                            }}
                          />
                        )}
                        <CardMedia
                          component="img"
                          sx={{
                            height: 150,
                            width: "100%",
                            objectFit: "cover",
                          }}
                          image={car?.imgUrls || R.img_nocarfound}
                          alt="Car Image"
                        />
                      </Box>
                      <CardContent>
                        <Box sx={{ display: "grid" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Tooltip
                              title={`${car?.make} ${car?.modalName} ${car?.varientName}`}
                            >
                              <Typography
                                noWrap={true}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                variant="body1"
                                component="span"
                                fontWeight={600}
                              >
                                {`${car?.make} ${car?.modalName} ${car?.varientName}`
                                  .length > 30
                                  ? `${car?.make} ${car?.modalName} ${car?.varientName}`.substring(
                                      0,
                                      27
                                    ) + "..."
                                  : `${car?.make} ${car?.modalName} ${car?.varientName}`}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body1" color="textSecondary">
                              Sku id :
                            </Typography>
                            <Typography variant="body1" color="h6">
                              <SkuId leadCarList={car} />
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body1" color="textSecondary">
                              Price
                            </Typography>
                            <Typography variant="body2" color="h6">
                              {formatIndianCurrency(car?.msp_price)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "5px",
                            }}
                          >
                            <Typography variant="body2" color="textSecondary">
                              Fuel
                            </Typography>
                            <Typography variant="body2" color="h6">
                              {car?.fuelDesc}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "5px",
                            }}
                          >
                            <Typography variant="body2" color="textSecondary">
                              Body Type
                            </Typography>
                            <Typography variant="body2" color="h6">
                              {car?.bodyType}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2" color="textSecondary">
                              Vehicle No.
                            </Typography>
                            <Typography variant="body2" color="h6">
                              {car?.reg_no}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "5px",
                            }}
                          >
                            <Typography variant="body2" color="textSecondary">
                              Transmission
                            </Typography>
                            <Typography variant="body2" color="h6">
                              {car?.transmissionDesc}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>

              {/* Custom Navigation Buttons */}
              <div
                className="custom-swiper-button-next"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  zIndex: 10,
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
              >
                <ArrowCircleRightIcon fontSize="large" />
              </div>
              <div
                className="custom-swiper-button-prev"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  zIndex: 10,
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
              >
                <ArrowCircleLeftIcon fontSize="large" />
              </div>
            </div>
          ) : (
            <List
              sx={{
                width: "99%",
                bgcolor: "background.paper",
                paddingRight: "17px",
                gap: "10px",
                overflow: "hidden",
                display: { xs: "none", sm: "block" },
              }}
            >
              {leadCarList?.map((car, index) => (
                <Grid
                  key={index}
                  sx={{
                    backgroundColor: "#fff",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    margin: "10px 0",

                    borderRadius: "5px",
                    ":hover": {
                      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                      cursor: "pointer",
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                  container
                  spacing={2}
                >
                  <Grid
                    sx={{ padding: "0px !important" }}
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={2}
                    style={{
                      backgroundColor: "#f5f5f5",
                    }}
                    onClick={() => onEditClick(car, "upload-images")}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        height: 130,
                        width: "100%",
                        objectFit: "cover",
                        margin: "auto",
                      }}
                      image={car?.imgUrls || R.img_nocarfound}
                      alt="Car Image"
                      onClick={() => onEditClick(car, "upload-images")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={7} md={7} lg={7}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            color: "#121b5b",
                          }}
                          noWrap
                          variant="body1"
                          component="h5"
                        >
                          {car?.makeYear} {car?.make} {car?.modalName}{" "}
                          {car?.varientName}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            color: "#121b5b",
                            mx: "5px",
                            mb: "5px",
                          }}
                          noWrap
                          variant="body1"
                          component="h6"
                        >
                          ||
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Sku id :
                        </Typography>
                        <Typography variant="body1" color="h6">
                          <SkuId leadCarList={car} />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: "10px", fontStyle: "italic" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        {car?.cityName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        |
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Listed on :
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {car?.created_date
                          ? new Date(car.created_date).toLocaleDateString()
                          : "NA"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        |
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Updated on:
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {car?.updated_date
                          ? new Date(car.updated_date).toLocaleDateString()
                          : "NA"}
                      </Typography>
                    </Box>

                    <CardContent sx={{ padding: "0px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "30px",
                          marginTop: "7px",
                          height: "30px",
                        }}
                      >
                        {car?.fuelDesc && (
                          <Box sx={{ display: "grid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                <LocalGasStationIcon
                                  sx={{ fontSize: "18px" }}
                                />
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  textTransform: "capitalize",
                                }}
                                variant="body1"
                                color="h6"
                              >
                                {car?.fuelDesc?.toLowerCase()}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {car?.drivenKms && (
                          <Box sx={{ display: "grid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                <NetworkCheckIcon sx={{ fontSize: "18px" }} />
                              </Typography>
                              <Typography
                                sx={{ fontSize: "15px" }}
                                variant="body1"
                                color="h6"
                              >
                                {new Intl.NumberFormat().format(car.drivenKms) +
                                  " Km"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {car?.reg_no && (
                          <Box sx={{ display: "grid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                marginTop: "5px",
                                gap: "10px",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                <CarRentalIcon sx={{ fontSize: "18px" }} />
                              </Typography>
                              <Typography
                                sx={{ fontSize: "15px" }}
                                variant="body1"
                                color="h6"
                              >
                                {car?.reg_no}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {car?.transmissionDesc && (
                          <Box sx={{ display: "grid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                <GiGearStick style={{ fontSize: "18px" }} />
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  textTransform: "capitalize",
                                }}
                                variant="body1"
                                color="h6"
                              >
                                {car?.transmissionDesc?.toLowerCase()}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {car?.bodyType && (
                          <Box sx={{ display: "grid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                <TimeToLeaveIcon sx={{ fontSize: "18px" }} />
                              </Typography>
                              <Typography
                                sx={{ fontSize: "15px" }}
                                variant="body1"
                                color="h6"
                              >
                                {car?.bodyType}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {car?.colour && (
                          <Box sx={{ display: "grid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                <ColorLensIcon sx={{ fontSize: "18px" }} />
                              </Typography>
                              <Typography
                                sx={{ fontSize: "15px" }}
                                variant="body1"
                                color="h6"
                              >
                                {car?.colour}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <CardContent>
                      {car?.msp_price && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "25px" }}
                            variant="h5"
                            color="h6"
                          >
                            ₹ {formatIndianCurrency(car?.msp_price)}
                          </Typography>
                        </Box>
                      )}{" "}
                      {car?.sub_source_name == "Recommended" && (
                        <Chip label={car?.sub_source_name} />
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              ))}
            </List>
          )}
        </>
        <CustomizedDialogs
          open={open}
          handleClose={handleClose}
          rowSkuId={dialogBox.id}
          titleMessage={dialogBox.titleMessage}
          confirmMessage={dialogBox.confirmMessage}
          showField={dialogBox.showField}
          handleConfirm={dialogBox.handleConfirm}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export function CustomizedDialogs({
  open,
  handleClose,
  rowSkuId,
  handleDelete,
  titleMessage,
  confirmMessage,
  handleConfirm,
  showField,
}) {
  const [reason, setReason] = useState("");

  const handleConfirmClick = async () => {
    if (handleConfirm) {
      await handleConfirm(reason);
      setReason("");
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          width={500}
          sx={{ paddingX: "16px" }}
        >
          {titleMessage}
        </DialogTitle>

        <DialogContent>
          <Typography gutterBottom width={500} fontSize={18}>
            {confirmMessage}
            {showField === "1" && (
              <TextField
                fullWidth
                margin="dense"
                label="Reason for cancellation"
                variant="outlined"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            sx={{
              color: "#4462de",
              ":hover": { color: "#4462de" },
              textTransform: "none",
              letterSpacing: "0.7px",
            }}
            onClick={handleConfirmClick}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            size="large"
            sx={{
              color: "#4462de",
              ":hover": { color: "#4462de" },
              textTransform: "none",
              letterSpacing: "0.7px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
// import PrimaryHeading from "../../../../../components/primary-heading/PrimaryHeading";
import { useNavigate } from "react-router-dom";
import { buyerApi } from "../../../api/buyerLeadApi";
import { publicApi } from "../../../api/publicApi";
// import { showToast } from "../../../../../components/toaster/Toaster";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import MultiSelect from "../../../components/buttons/multiSelect";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Step1 = ({ id, brandFilter, setBrandFilter }) => {
  
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [temporaryStatus, setTemporaryStatus] = useState({});
  const [comments, setComments] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [error, setError] = useState(false);

  const formatPrice = (value) => {
    const number = Number(value.replace(/[^0-9]/g, ""));

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [customerData, setCustomerData] = useState({
    fuelType: [],
    makeIdList: [],
    modelIdList: [],
    otherRequirement: "",
    priceFrom: null,
    priceTo: null,
    yearFrom: "",
    transmissionType: "",
    // ownerType: null,
  });

  const [payload, setPayload] = useState({
    customerId: +id.id,
    statusId: null,
    followDate: null,
    comment: null,
  });

  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const navigate = useNavigate("");

  const handleStatusChange = (event) => {
    const statusId = event.target.value;
    setPayload((prev) => ({ statusId: statusId }));
    setTemporaryStatus((prevStatus) => ({
      [id]: statusId,
    }));
  };

  const customer = async (params = {}) => {
    try {
      const res = await buyerApi.getCustomerInfo({ leadId: id });
      setCustomerList(res?.data);
      setBasicDetails({
        name: res?.data[0]?.name || null,
        mobile: res?.data[0]?.mobile || null,
        email: res?.data[0]?.email || null,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    customer();
  }, []);

  const [make, setMake] = useState([]);
  const [modal, setModal] = useState([]);

  const [preferences, setPreferences] = useState({
    leadId: id,
    make: [],
    model: [],
    transmissionType: null,
    fuelType: [],
    // ownerType: null,
    priceFrom: "",
    priceTo: null,
    yearFrom: null,
    otherRequirement: "",
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - 1999),
    (val, index) => 2000 + index
  );

  const fuelTypeMapping = {
    Petrol: "1",
    Diesel: "2",
    Electric: "3",
    CNG: "4",
  };

  const handleBrandChange = async (selectedItems) => {
    const selectedValues = selectedItems.map((item) => item.value);
    setMake(selectedItems);
    setBrandFilter(selectedItems);
    //setCustomerData(prev=>({...prev, makeIdList: selectedValues,}));
    setPreferences((prev) => ({
      ...prev,
      make: selectedValues,
    }));

    if (selectedValues.length > 0) {
      try {
        const res = await buyerApi.getModels({
          make: `[${selectedValues.join(",")}]`,
          is_group_model: true,
        });
        setModels(res.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    } else {
      setModels([]);
    }
  };

  const handleModelChange = (selectedItems) => {
    const selectedValue = selectedItems.map((item) => item.value);
    setModal(selectedItems);
    //setCustomerData(prev=>({...prev, modelIdList: selectedValue,}));
    setPreferences((prev) => ({
      ...prev,
      model: selectedValue,
    }));
  };

  const handleFuelTypeChange = (selectedItems) => {
    const selectedValues = selectedItems.map((item) => item.value);
    setPreferences((prev) => ({
      ...prev,
      fuelType: selectedValues,
    }));
  };

  const fetchCustomerPreferences = async () => {
    try {
      const res = await buyerApi.getLeadPrefrence(id);

      const fuelTypeMappings = {
        1: "Petrol",
        2: "Diesel",
        3: "Electric",
        4: "CNG",
      };

      // Extracting lead preferences from the response
      const leadPreferences = res?.data?.leadPrefrences || {};
      // Map the new structure to the expected format
      const preferences = {
        fuelType:
          leadPreferences.fuelType?.map((type) => fuelTypeMappings[type]) || [],
        makeIdList: leadPreferences.makeIdList || [],
        modelIdList: leadPreferences.modelIdList || [],
        otherRequirement: leadPreferences.otherRequirement || "",
        priceFrom: leadPreferences.priceFrom || null,
        priceTo: leadPreferences.priceTo || null,
        yearFrom: leadPreferences.yearFrom || "",
        transmissionType: leadPreferences.transmissionType || "",
        // ownerType: res.data?.ownerType,
      };

      setCustomerData(preferences);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchCustomerPreferences();
  }, []);

  useEffect(() => {
    if (brands.length && customerData.makeIdList.length) {
      const selectedBrands = customerData.makeIdList
        .map((makeId) => {
          const brand = brands.find((brand) => brand.makeId === +makeId);
          return brand ? { label: brand.makeName, value: brand.makeId } : null;
        })
        .filter(Boolean);

      setPreferences((prev) => ({
        ...prev,
        make: selectedBrands,
      }));
      handleBrandChange(selectedBrands);
      setMake(selectedBrands);
      setBrandFilter(selectedBrands);
    }
  }, [brands, customerData.makeIdList]);

  useEffect(() => {
    if (models.length && customerData.modelIdList.length) {
      const selectedModels = customerData.modelIdList
        .map((modelId) => {
          const model = models.find((model) => model.groupId === +modelId);
          return model
            ? { label: model.groupModelName, value: model.groupId }
            : null;
        })
        .filter(Boolean);

      setPreferences((prev) => ({
        ...prev,
        model: selectedModels,
      }));
      handleModelChange(selectedModels);
      setModal(selectedModels);
    }
  }, [models, customerData.modelIdList]);

  useEffect(() => {
    if (customerData) {
      setPreferences((prevPreferences) => ({
        ...prevPreferences,
        transmissionType: customerData.transmissionType || "",
        fuelType: customerData.fuelType || [],
        priceFrom: customerData.priceFrom || "",
        priceTo: customerData.priceTo || "",
        yearFrom: customerData.yearFrom || "",
        otherRequirement: customerData.otherRequirement || "",
        // ownerType: +customerData.ownerType || "",
      }));

      setComments(customerData.otherRequirement || "");
    }
  }, [customerData]);

  const fetchBrands = async () => {
    try {
      const res = await publicApi.brandList();
      const filteredBrands = res.data.filter((brand) => brand.makeId >= 2);
      setBrands(filteredBrands);
    } catch (error) {
      console.log(error);
    }
  };

  const formatIndianCurrency = (amount) => {
    if (!amount) return "";

    const x = amount.toString().replace(/\D/g, "");

    const lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);

    const formattedValue =
      otherNumbers !== ""
        ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
        : lastThree;

    return formattedValue;
  };

  const handlePriceFrom = (e) => {
    const value = e.target.value;
    // const formattedValue = formatIndianCurrency(value);

    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      priceFrom: value.toString().replace(/,/g, ""),
    }));
  };

  const handlePriceTo = (e) => {
    const value = e.target.value;
    const formattedValue = formatIndianCurrency(value);

    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      priceTo: value.toString().replace(/,/g, ""),
    }));
  };

  const handleSave = async () => {
    try {
      if (preferences.priceFrom < 100000) {
        toast("Min Price must be greater than or equal to 1 lakh");
        return;
      }
      if (preferences.priceTo < 100000) {
        toast("Max Price must be greater than or equal to 1 lakh");
        return;
      }

      if (preferences.yearFrom < 2000) {
        toast("Min Year must be greater than or equal to 2000");
        return;
      }
      if (preferences.yearFrom > currentYear) {
        toast(`Max Year must be less than or equal to ${currentYear}`);
        return;
      }
      if (preferences.yearFrom > preferences.yearTo) {
        toast("Min Year cannot be greater than Max Year");
        return;
      }

      preferences.fuelType = preferences.fuelType.map(
        (fuel) => fuelTypeMapping[fuel]
      );

      const response = await buyerApi.saveLeadPrefrence(preferences);
      console.log("response save response ", response);
      fetchCustomerPreferences(id);
      customer();
      toast(response.message);
    } catch (error) {
      console.log(error);
      toast(error.message);
    }
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    const currentYear = moment().year();

    if (year > currentYear || year < 2000) {
      setError(true);
    } else {
      setError(false);
    }
    setPreferences((prev) => ({ ...prev, yearFrom: year }));
  };

  return (
    <div>
      <div>
        <Box
          sx={{
            backgroundColor: "#121b5b",
            padding: "5px",
            pl: "10px",
            color: "white",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6">Customer Preferences</Typography>
        </Box>
        <Box sx={{ padding: "15px 10px 0px 10px" }}>
          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Select Brand : </p>
              <MultiSelect
                containerStyle={style["selectable-button-container-style"]}
                name="brand"
                handleChange={handleBrandChange}
                items={brands.map((brand) => ({
                  label: brand.makeName,
                  value: brand.makeId,
                }))}
                selectedItems={make}
                hint={"Select Brands"}
                isMulti={true}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Select Model : </p>
              <MultiSelect
                containerStyle={style["selectable-button-container-style"]}
                name="model"
                handleChange={handleModelChange}
                items={models.map((model) => ({
                  label: model.groupModelName,
                  value: model.groupId,
                }))}
                selectedItems={modal}
                hint={"Select Models"}
                isMulti={true}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Fuel Type : </p>
              <MultiSelect
                containerStyle={style["selectable-button-container-style"]}
                name="fuelType"
                handleChange={handleFuelTypeChange}
                items={[
                  { label: "Petrol", value: "Petrol" },
                  { label: "Diesel", value: "Diesel" },
                  { label: "CNG", value: "CNG" },
                  { label: "Electric", value: "Electric" },
                ]}
                selectedItems={preferences.fuelType.map((fuel) => ({
                  label: fuel,
                  value: fuel,
                }))}
                hint={"Select Fuel Types"}
                isMulti={true}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Transmission : </p>
              <TextField
                select
                size="small"
                style={{ width: "100%" }}
                value={preferences.transmissionType}
                onChange={(e) =>
                  setPreferences((prev) => ({
                    ...prev,
                    transmissionType: e.target.value,
                  }))
                }
              >
                <MenuItem value="">Select Transmission</MenuItem>
                <MenuItem value="2">Automatic</MenuItem>
                <MenuItem value="1">Manual</MenuItem>
              </TextField>
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Minimum Model Year : </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <TextField
                  size="small"
                  style={{ width: "100%" }}
                  type="number"
                  value={preferences.yearFrom}
                  onChange={handleYearChange}
                  InputProps={{
                    inputProps: {
                      min: 2000,
                      max: moment().year(),
                    },
                  }}
                  placeholder="Enter Year"
                />
                {error && (
                  <p className={style["error-text"]}>
                    Year must be between 2000 and {moment().year()}.
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Owners : </p>
              <TextField
                select
                size="small"
                style={{ width: "100%" }}
                value={preferences.ownerType}
                onChange={(e) =>
                  setPreferences((prev) => ({
                    ...prev,
                    ownerType: +e.target.value,
                  }))
                }
              >
                <MenuItem value="">Select Owners</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
              </TextField>
            </div>
          </div> */}

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Price Range </p>
              <div className={style["price-container"]}>
                <TextField
                  type="text"
                  size="small"
                  placeholder="Min Price"
                  value={formatIndianCurrency(preferences.priceFrom)}
                  onChange={(e) => handlePriceFrom(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹ </InputAdornment>
                    ),
                  }}
                />

                {/* <span className={style['-span']}>-</span> */}
                <div>
                  <TextField
                    type="text"
                    size="small"
                    placeholder="Max Price"
                    value={formatIndianCurrency(preferences.priceTo)}
                    onChange={(e) => handlePriceTo(e)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Additional Comments :</p>
              <TextField
                fullWidth
                multiline
                rows={2}
                value={comments}
                onChange={(e) => {
                  setComments(e.target.value);
                  setPreferences((prevPreferences) => ({
                    ...prevPreferences,
                    otherRequirement: e.target.value,
                  }));
                }}
                placeholder="Enter any additional comments here..."
                variant="outlined"
              />
            </div>
          </div>
        </Box>
        <div className={style["save-button-section"]}>
          <Button
            size="small"
            onClick={handleSave}
            variant="contained"
            className={style["save-button"]}
            style={{ backgroundColor: "#121b5b" }}
          >
            Save
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Step1;

import React, { useState, useEffect, useRef } from "react";
import style from "./lead.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
// import SearchBar from "../../../components/search-bar/SearchBar";
// import { getAbsolute, MAIN_ROUTES } from "../../../constants/routes";
import { showToast } from "../../components/toaster/toaster";
// import { getDealerList } from "../../../store/dealer/dealerAction";
// import SelectableButton from "../../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
// import CarDetails from "./car-details/CarDetails";
import moment from "moment";
// import DetailTable from "../../../components/tables/DetailTable";
import LeadTable from "../../components/tables/LeadTable";
// import getData from "../../components/services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { buyerApi } from "../../api/buyerLeadApi";
// import { publicApi } from "../../../api/publicApis";
// import { IoEyeSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
// import MultiSelect from "../../../components/buttons/multiSelect";
import { GrDocumentDownload } from "react-icons/gr";
// import { MdUploadFile } from "react-icons/md";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton,Pagination, Stack, Tooltip,Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FaArrowLeft } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { IoMdDownload } from "react-icons/io";
import DatePicker from "react-datepicker";


export default function LeadList() {
  const csvFileUrl =
    "https://storage.googleapis.com/motorpedia/js/admin_1127/20250228_163359_20250113_133817_bulk_lead_upload_sample_csv_format.csv.csv";

  const handleDownload = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = csvFileUrl;
    link.setAttribute("download", "sample.csv"); // Set the download attribute with a default file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM by removing the link
  };

  const dispatch = useDispatch();
  const toDateInputRef = useRef(null);
  const fromDateInputRef = useRef(null);
  // const [start_date, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  

  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    vehicle_id: "",
    brand_id: "",
    model_id: "",
    variant_id: "",
    dealer_id: "",
    processId: "",
    start_date: "",
    end_date: "",
  });

  const statusList = [
    { id: 5, name: "Select Status" },
    { id: 0, name: "Pending" },
    { id: 1, name: "Completed" },
  ];

  const [varientList, setVarientList] = useState([]);
  const [csvList, setCarList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  //   const { dealerList } = useSelector((state) => state.dealer);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, sethasNextPage] = useState();

  console.log("currentPage:", currentPage);
  console.log("totalPageCount:", totalPageCount);
  console.log("totalCount:", totalCount);
  console.log("hasNextPage:", hasNextPage);
  const [processIds, setProcessIds] = useState(5);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 0,
  });

  const check = () => {
    buyerApi
      .getLeadCsvList()
      .then((response) => {
        console.log("Response from getLeadCsvList:", response);
        setCarList(response.payload.data?.data);
        setCurrentPage(response.payload.data?.currentPage);
        setTotalPageCount(response.payload.data?.totalPageCount);
        console.log("total page ", response.payload.totalPageCount);
        setPagination(response.payload.data?.totalPageCount);
        setTotalCount(response.payload.data?.totalCount);
        sethasNextPage(response.payload.hasNextPage);
      })
      .catch((error) => {
        console.error("Error fetching car list:", error);
      });
  };

  useEffect(() => {
    check();
  }, []);

  useEffect(() => {
    const fetchFilteredCsvList = () => {
      buyerApi
        .getLeadCsvList({
          processed: filter?.processId,
          from: filter?.start_date,
          to: filter?.end_date,
          page: currentPage,
        })
        .then((response) => {
          setCarList(response.payload.data?.data);
          setTotalPageCount(response.payload.totalPageCount);
          setTotalCount(response.payload.totalCount);
          sethasNextPage(response.payload.hasNextPage);
        })
        .catch((error) => {
          console.error("Error fetching filtered car list:", error);
        });
    };
    fetchFilteredCsvList();
  }, [filter.processId, filter.start_date, filter.end_date, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const handleNextPage = () => {
  //   if (hasNextPage) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage((prevPage) => prevPage - 1);
  //   }
  // };

  useEffect(() => {
    // dispatch(
    //   getDealerList({
    //     pagesize: 100,
    //   })
    // );
  }, []);

  const exportToExcel = () => {
    // Create a new array of objects excluding 'user_id', renaming 'lead_id' to 'sheet_id',
    // and mapping 'processed' to "Pending" or "Submitted"
    const dataWithSheetIdFirst = csvList.map(
      ({ user_id, lead_id, processed, ...rest }) => ({
        sheet_id: lead_id, // Place 'sheet_id' first
        processed: processed == 1 ? "Submitted" : "Pending", // Map 'processed' to "Submitted" or "Pending"
        ...rest, // Spread the rest of the properties
      })
    );

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithSheetIdFirst);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "CustomerCsvLst.xlsx");
    });
  };

  const checkNum = () => {
    // navigate(getAbsolute(MAIN_ROUTES.INVENTORY.NEW_INVENTORY));
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        toast.error("Please upload a file before submitting.");
        return;
      }

      const formData = new FormData();
      formData.append("csvFile", selectedFile);

      const response = await buyerApi.postCsvList(formData);
      check(); // Fetch new data after the upload
      toast.success("Csv Uploaded successfully");

      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; 
      }
    } catch (error) {
     
      const invalidRows = error.response?.data?.invalidRows || [];
      const formattedInvalidRows = invalidRows.length ? invalidRows.join(", ") : "None";

        toast.error(`${  error.response?.data?.message || "Error uploading file" } Invalid rows: ${formattedInvalidRows}`,  {},
        true
      );
    }
  };

  const searchFilter = () => {
    if (processIds !== 5) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        processId: processIds,
      }));
    }

    if (startDate !== "") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        start_date: startDate,
      }));
    }

    if (endDate !== "") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        end_date: endDate,
      }));
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Lead CSV List
        </Typography>

        <Box>
          <div className={style["headar-upload-container"]}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VisuallyHiddenInput
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <Button
                variant="outlined"
                sx={{
                  color: "#121b5b",
                  border: "1px solid #121b5b",
                }}
                onClick={() =>
                  fileInputRef.current && fileInputRef.current.click()
                }
              >
                {selectedFile ? (
                  <Tooltip title={selectedFile.name}>
                    <Typography
                      noWrap
                      variant="body1"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedFile.name.split("").slice(0, 10).join("")}...
                    </Typography>
                  </Tooltip>
                ) : (
                  "Choose File"
                )}
              </Button>
              {selectedFile && (
                <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                  {/* <Typography variant="body1" sx={{ mr: 1 }}>
                    {selectedFile.name}
                  </Typography> */}
                  <IconButton
                    size="small"
                    onClick={() => setSelectedFile(null)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>

            <PrimaryButton
              containerStyle={style["add-new-car-button-container-style"]}
              // leadingIcon={R.ic_add_white}
              name={"Submit"}
              fontSize={"1rem"}
              onClick={handleUpload}
            />
          </div>
        </Box>
      </Box>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={style["headar-button-container"]}></div>
      </div>

      <div className={style["filters-container"]}>
        <div className={style["filter-cont"]}>
          <div className={style["filters-wrapper"]}>
            <div className={style["input-field"]}>

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                className={style["date-change"]}
                dateFormat="dd-MM-yyyy"
                ref={fromDateInputRef}
              />
            </div>

            {/* 
            <div className={style["input-field"]}>
              <input
                className={style["date-change"]}
                type={"date"}
                name="setStartDate"
                value={startDate}
                ref={fromDateInputRef}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                onClick={() => fromDateInputRef.current.showPicker()}
                placeholder="Start Date"
              />
            </div> */}

            <div className={style["input-field"]}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
                dateFormat="dd-MM-yyyy"
                className={style["date-change"]}
                ref={toDateInputRef}
              />
            </div>

            {/* <div className={style["input-field"]}>
              <input
                className={style["date-change"]}
                type={"date"}
                name="setEndDate"
                value={endtDate}
                ref={toDateInputRef}
                onChange={(e) => {
                  setendDate(e.target.value);
                }}
                onClick={() => toDateInputRef.current.showPicker()}
                placeholder="End Date"
              />
            </div> */}
          </div>
          <div>
            <button className={style["reset-button"]} onClick={searchFilter}>
              Search
            </button>
          </div>
          <div>
            <button
              className={style["reset-button"]}
              onClick={() => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  processId: "",
                  start_date: "",
                  end_date: "",
                }));
                setStartDate("");
                setEndDate("");
                setProcessIds(5);
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className={style["button-all-conatiner"]}>
          <div>
            <a href={csvFileUrl} download="sample.csv">
              <button
                className={style["download-button"]}
                onClick={handleDownload}
              >
                <IoMdDownload className={style["icon"]} />
                Download Sample CSV Format
              </button>
            </a>
          </div>
        </div>
      </div>

      <LeadTable data={csvList} check={check} />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={totalPageCount}
          page={currentPage}
          onChange={(event, page) => handlePageChange(page)}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      <ToastContainer />
    </div>
  );
}

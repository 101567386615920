
import { useReactTable, flexRender, getCoreRowModel } from "@tanstack/react-table";
import style from "../../buyer-lead/buyerLead.module.scss";
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Pagination, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import daynamicpage from "../../../api/daynamicpage";
import { showToast } from "../../../components/toaster/toaster";
import { getFaqsPages } from "../../../store/faqs/faqsAction";
import { useDispatch } from "react-redux";
import { CustomizedDialogs } from "../../../components/tables/DetailTable";
import { useState, useEffect } from "react";
import { carApi } from "../../../api/carApi";

export function DynamicList({ value }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cities, setCities] = useState({});
    const [dialogBox, setDialogBox] = useState({
        id: "",
        titleMessage: "",
        confirmMessage: "",
        handleConfirm: () => { },
    });
    const [open, setOpen] = useState(false);
    const [pages, setpages] = useState([]);
    const handleClose = () => {
        setOpen(false);
    };

    const onEditClick = (data) => {
        navigate("/create-daynamic-page", { state: { edited: data } })
    }

    const handleDelete = async (id) => {
        try {
            const res = await daynamicpage.removeDynamicpages({ id: id });
            showToast("warning", res?.message);
            await daynamicpage.getDynamicPage();
            handleClose();
        } catch (error) {
            console.log("err =>", error)
        }
    }


    const getAllPages = async () => {
        try {
            const res = await daynamicpage.getAllPages();
            setpages(res?.data)
        } catch (error) {
            console.log(error?.message)
        }
    }

    useEffect(() => {
        getAllPages();
    }, [])


    const onDelete = async (id) => {
        setDialogBox({
            id: id,
            titleMessage: "! Warning",
            confirmMessage: "Are you sure you want to delete this page",
            handleConfirm: () => handleDelete(id),
        });
        setOpen(true);

    }

    const getpageNaame = (id) => {
        const name = pages.find(item => item?.id == id);
        return name?.page_name;
    }


    const ActionButtons = ({ data }) => {
        return (
            <Stack direction="row" spacing={1}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => onEditClick(data)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                    <IconButton onClick={() => onDelete(data.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        );
    };


    return (
        <div
            className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
        >
            <TableContainer
                component={Paper}
                sx={{ marginTop: '20px' }}
            >
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: "#f5f5f5",
                            "& .MuiTableCell-root": {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell align="left">Page Id</TableCell>
                            <TableCell align="left">Page Title</TableCell>
                            <TableCell align="left">Parent Page</TableCell>
                            <TableCell align="left">City Name</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(Array.isArray(value) ? value : []).map((car, index) => (
                            <TableRow key={index}>
                                <TableCell align="left">{car?.id || "N/A"}</TableCell>
                                <TableCell align="left" style={{ width: "30%" }}>
                                    {car?.title
                                        ? car?.title.length > 50
                                            ? car?.title.substring(0, 50) + "..."
                                            : car?.title
                                        : "No title"}
                                </TableCell>
                                <TableCell align="left">{getpageNaame(car?.page_id) || "N/A"}</TableCell>
                                <TableCell align="left">{car?.city_name || "N/A"}</TableCell>
                                <TableCell align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ActionButtons data={car} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <CustomizedDialogs
                open={open}
                handleClose={handleClose}
                rowSkuId={dialogBox.id}
                titleMessage={dialogBox.titleMessage}
                confirmMessage={dialogBox.confirmMessage}
                handleConfirm={dialogBox.handleConfirm}
            />

        </div>
    );
}

import React, { useState } from "react";
import style from "./blogTable.module.scss";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { R } from "../../../constants/resources";
import { blogs } from "../../../api/blogs";
import { HiOutlineExternalLink } from "react-icons/hi";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import EditIcon from "@mui/icons-material/Edit";
import { CustomizedDialogs } from "../../../components/tables/DetailTable";
import DeleteIcon from "@mui/icons-material/Delete";

export default function BlogTable({ data = [], fetchBlogs }) {
  const navigate = useNavigate();
  const [view, setView] = useState(window.innerWidth > 1200 ? "list" : "grid");
  const [open, setOpen] = useState(false);
  const [dialogBox, setDialogBox] = useState({
    id: "",
    titleMessage: "",
    confirmMessage: "",
    handleConfirm: () => { },
  });
  console.log('dailougs ==>', dialogBox);


  const handleViewChange = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const StatusLabel = ({ status }) => {
    let label;
    switch (status) {
      case "1":
        label = "Published";
        break;
      case "0":
        label = "Archived";
        break;
      case "2":
        label = "Draft";
        break;
      default:
        label = "Unknown";
    }

    return <span
      style={{
        backgroundColor: "#F7DC6F",
        borderRadius: "5px",
        width: "100px", 
        display: "inline-block", // Ensures the width is respected
        textAlign: "center", // Centers the text within the span
        padding: "6px",
        fontSize: "13px",
        boxSizing: "border-box", // Ensures padding doesn't affect the width
      }}
    >{label}</span>;
  };

  const getDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await blogs.setStatus({ status: 0, blog_id: id });
      fetchBlogs();
    } catch (error) {
      console.log("erorr =>", error);
    }
  }


  const onDelete = (id) => {
    setDialogBox({
      id: id,
      titleMessage: "Warning!",
      confirmMessage: "Are you sure you want to delete this blog?",
      handleConfirm: () => handleDelete(id),
    });
    setOpen(true);
  };


  const onToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === "1" ? "2" : "1";
    const dateClicked = new Date().toISOString().slice(0, 19).replace("T", " ");

    try {
      await blogs.setStatus({
        status: newStatus,
        blog_id: id,
        published_date: newStatus === "1" ? dateClicked : null,
      });

      console.log({
        blog_id: id,
        previousStatus: currentStatus,
        newStatus: newStatus,
        dateClicked: dateClicked,
      });

      fetchBlogs(); // Optionally refresh the data or trigger a re-render
    } catch (error) {
      console.log("Error during status update:", error);
    }
  };

  const columns = React.useMemo(
    () => [
      { accessorKey: "title", header: "Title" },
      { accessorKey: "author", header: "Author" },
      {
        accessorKey: "create_at",
        header: "Created Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "published_date",
        header: "Published Date",
        cell: (info) => {
          const value = info.row.original.published_date;
          return value ? getDate(value) : "-";
        },
      },
      {
        accessorKey: "action",
        header: "Actions",
        cell: ({ row }) => <ActionButtons data={row.original} />,
      },
    ],
    []
  );



  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const ActionButtons = ({ data }) => {
    const isPublished = data.status === "1";

    return (
      <div className={style["action-container"]}>
        {/* <img
          src={R.ic_edit_pen}
          alt="edit"
          onClick={() => onEditClick(data)}
          className={style["action-icon"]}
          style={{ cursor: isPublished ? "not-allowed" : "pointer" }}
        />
        <img
          src={R.ic_delete}
          alt="delete"
          onClick={() => !isPublished && onDelete(data.id)}
          className={style["action-icon"]}
          style={{ cursor: isPublished ? "not-allowed" : "pointer" }}
        /> */}
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Tooltip title="Edit">
            <IconButton onClick={() => onEditClick(data, "edit-car-details")}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton onClick={() => onDelete(data.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </div>
    );
  };

  const StatusButtons = ({ data }) => {
    const isPublished = data.status === "1";

    return (
      <div className={style["action-container"]}>
        <button
          onClick={async () => {
            await onToggleStatus(data.id, data.status);
          }}
          className={`${style["toggle-button"]} ${isPublished ? style["greyish-text"] : ""
            }`}
        >
          {isPublished ? "Unpublish" : "Publish"}
        </button>
      </div>
    );
  };

  const onEditClick = (data) => {
    navigate("/create-blogs", { state: data });
  };

  return (
    <>
      <IconButton
        aria-label="toggle view"
        onClick={handleViewChange}
        sx={{
          ml: "auto",
          display: "flex",
          justifyContent: "flex-end",
          display: { xs: "none", md: "block" },
        }}
      >
        {view === "grid" ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>

      <div className={`${style["blog-table-container"]} table-responsive`}>
        <>
          {view === "grid" ? (
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {data?.map((car, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      margin: 1,
                      ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ height: "50px", overflow: "hidden" }}
                        variant="body1"
                        component="div"
                      >
                        {car?.title}
                      </Typography>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Author
                        </Typography>
                        <Typography variant="body2" color="h6">
                          {car?.author}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Created Date
                        </Typography>
                        <Typography variant="body2" color="h6">
                          {car?.create_at
                            ? new Date(car?.create_at).toLocaleDateString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Published Date
                        </Typography>
                        <Typography variant="body2" color="h6">
                          {car?.published_date
                            ? new Date(car?.published_date).toLocaleDateString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Status
                        </Typography>
                        <Typography variant="body2" color="h6">
                          <StatusLabel status={car?.status} />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          <ActionButtons data={car} />
                        </Typography>
                        <Typography variant="body2" color="h6">
                          <StatusButtons data={car} />
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <List>
              <TableContainer
                component={Paper}
                sx={{ width: "99%", margin: "0px 10px 0px 6px" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#f5f5f5",
                      "& .MuiTableCell-root": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell align="left" style={{ width: "35%" }}>Title</TableCell>
                      <TableCell align="left">Author</TableCell>
                      <TableCell align="left">Created Date</TableCell>
                      <TableCell align="left">Published Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      {/* <TableCell align="left">Update Status</TableCell> */}
                      <TableCell align="center" >Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((car, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell sx={{ ml: 2, width: "20%" }}>
                          {car?.title}
                        </TableCell>
                        <TableCell>{car?.author}</TableCell>


                        <TableCell>
                          {car?.create_at
                            ? new Date(car?.create_at).toLocaleDateString()
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {car?.published_date
                            ? new Date(car?.create_at).toLocaleDateString()
                            : "-"}
                        </TableCell>
                        <TableCell>
                          <StatusLabel status={car?.status} />
                        </TableCell>
                        {/* <TableCell>
                        </TableCell> */}
                        <TableCell className="d-flex aligen-item-center" style={{ width: '180px' }}>
                          <StatusButtons data={car} />
                          <ActionButtons data={car} style={{ width: "100px", display: "flex", alignItems: "flex-end" }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </List>
          )}
          <CustomizedDialogs
            open={open}
            handleClose={handleClose}
            titleMessage={dialogBox.titleMessage}
            confirmMessage={dialogBox.confirmMessage}
            handleConfirm={() => {
              dialogBox.handleConfirm();
              handleClose();
            }}
          />
        </>
      </div>
    </>
  );
}
